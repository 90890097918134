//PC_SI_01-PC_SI_02 - Importing necessary packages
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ThreePLGrid from "../components/3plDetails";
import ThreePLForm from "../components/3plForm";
import { AuthPageLayout } from "../components/AuthPageLayout";
import { ForgotPasswordComponent } from "../components/ForgotPassword";
import { HeaderComponent } from "../components/Header";
import ManageUsers from "../components/ManageUsers";
import { RequireAuth } from "../components/RequireAuth";
import { ResetPasswordComponent } from "../components/ResetPasswordComponent";
import { ResetSuccessful } from "../components/ResetSuccessful";
import PageNotFound from "../components/ResuableComponents/PageNotFound";
import { SigninComponent } from "../components/SigninComponent";
import { Verifications } from "../components/Verification";
import { RoleEnum, Theme } from "../enums/enums";
import { SignupComponent } from "../components/SignupComponent";
import Profile from "../components/Profile";
import AlertComponent from "../components/ResuableComponents/AlertComponent";
import { useTheme } from "../components/ThemeContext";

export const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authPaths = ["/", "/admin", "/etm", "/resetPassword", "/forgotPassword", "/verification", "/resetSuccessful", "/signup"]

  const { themeType, changeTheme } = useTheme();
  console.log(themeType);
  return (
    <>
    <div className={themeType === Theme.DARK ? "dark-theme body-bg-grey" : ""}>
      {authPaths.includes(location.pathname) ? (
        <Routes>
          <Route
            path="/"
            element={
              <AuthPageLayout>
                <SigninComponent showLoader={() => { }} hideLoader={() => { }} />
              </AuthPageLayout>
            }
          />
          <Route
            path="/signup"
            element={
              <AuthPageLayout>
                <SignupComponent showLoader={() => { }} hideLoader={() => { }} />
              </AuthPageLayout>
            }
          />
          <Route
            path="/admin"
            element={
              <AuthPageLayout>
                <SigninComponent showLoader={() => { }} hideLoader={() => { }} />
              </AuthPageLayout>
            }
          />
          <Route
            path="/etm"
            element={
              <AuthPageLayout>
                <SigninComponent showLoader={() => { }} hideLoader={() => { }} />
              </AuthPageLayout>
            }
          />
          <Route
            path="/resetPassword"
            element={
              <AuthPageLayout>
                <ResetPasswordComponent showLoader={() => { }} hideLoader={() => { }} />
              </AuthPageLayout>
            }
          />
          <Route
            path="/forgotPassword"
            element={
              <AuthPageLayout>
                <ForgotPasswordComponent showLoader={() => { }} hideLoader={() => { }} />
              </AuthPageLayout>
            }
          />
          <Route
            path="/verification"
            element={
              <AuthPageLayout>
                <Verifications showLoader={() => { }} hideLoader={() => { }} />
              </AuthPageLayout>
            }
          />
          <Route
            path="/resetSuccessful"
            element={
              <AuthPageLayout>
                <ResetSuccessful />
              </AuthPageLayout>
            }
          />
        </Routes>

      ) : (
        <>
         {sessionStorage.getItem("accessToken") ? <HeaderComponent /> : null}
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/profile" element={<Profile />} />
            <Route element={<RequireAuth allowedRoles={[RoleEnum.ETM_ADMIN]}></RequireAuth>}>
              <Route path="/3plOnboarding" element={<ThreePLForm />} />
              <Route path="/3pldetails" element={<ThreePLGrid />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[RoleEnum.THIRD_PARTY_ADMIN, RoleEnum.ETM_ADMIN]}></RequireAuth>}>
              <Route path="/manageusers" element={<ManageUsers />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
          </Routes>
          {
            (sessionStorage.getItem("isConfigurationComplete") == "false") && (location.pathname != "/profile") ?
              <div>
                <AlertComponent errHeading={'Alert'}
                  err={'Configuration details must be filled to enable the load tracking. Update the configurations to proceed further with tracking loads.'}
                  onClose={() => {
                    navigate('/profile');
                  }}></AlertComponent>
              </div> : <></>}
        </>
      )}
      </div>
    </>
  );
};
