import ReactPaginate from "react-paginate";
interface PaginationProps {
    totalCount: number;
    onPageChange: (selectedPage: any, totalCount: number) => void;
}

function PaginationComponent({ totalCount, onPageChange }: PaginationProps) {
    return (
        <ReactPaginate
        previousLabel={
            <span className="Page navigation example">
                <img src="images/previous-icon.svg" alt="previous-icon" className="previous-icon" />
            </span>
        }
        nextLabel={
            <span className="Page navigation example">
                <img src="images/next-icon.svg" alt="next-icon" className="next-icon" />
            </span>
        }
        previousClassName={'pagination mb-0 d-flex align-items-center'}
        nextClassName={'pagination mb-0 d-flex align-items-center'}
        previousLinkClassName={"border-0 custom-page-link d-flex align-items-center me-2"}
        nextLinkClassName={'border-0 custom-page-link d-flex align-items-center ms-2'}
        disabledClassName={'disabled'}
        breakLabel={'...'}
        breakClassName={"page-link border-0 custom-page-link bg-transparent align-items-center d-flex font-14 secondary-text-color"}
        breakLinkClassName={'page-link'}
        // Total number of pages
        pageCount={Math.ceil(totalCount / 10)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={(selectedPage) => onPageChange(selectedPage, totalCount)} // Call onPageChange with selectedPage and totalCount

        containerClassName={'pagination mb-0 d-flex align-items-center'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link border-0 custom-page-link bg-transparent align-items-center d-flex font-14 secondary-text-color'}
        activeClassName={'active h-auto'}
        hrefBuilder={() => '#'}
    />
    );
}
 
export default PaginationComponent;