
// 3plForm.tsx
// PS_FP_1.0- importing of all the necessary package.
import all from 'country-calling-code';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigOptions, ErrorMessage, OrganizationDetails, RetrieveData, companyError, credentials } from '../interface/Interface';
import { ConnectionTest, post3plForm, retrieveConfigurationOptions, retrieveOrganizationData } from '../service/Api';
import { decryptData, encryptData } from '../utils/utils';
import Loader from './ResuableComponents/Loader';
import { SomethingWentWrong } from './ResuableComponents/SomethingWentWrong';

// Assuming toastify is being used for pop-up messages
// 3PLOrganizationDetails Interface - Assuming it includes relevant fields as per requirements
function ThreePLForm() {
    // PS_FP_1.1- variable declaration
    const navigate = useNavigate();
    const initialExistError: companyError = {
        companyName: '',
        userName: ''
    }
    const [authDetails, setFormData] = useState<credentials>({
        clientId: '',
        clientSecret: '',
        userName: '',
        password: ''
    });
    const [existErrorMessage, setExistErrorMessage] = useState<companyError>(initialExistError)
    const [showPass, setShowPass] = useState<string>('password');
    const [loader, setLoader] = useState<boolean>(false)
    let configOption: ConfigOptions
    const initialErrorMessage: ErrorMessage = {
        companyName: '',
        userName: '',
        businessEmailAddress: '',
        phoneNumber: '',
        companyStatus: '',
        expiresOn: '',
        textLocateStartTime: '',
        textLocateEndTime: '',
        trackingProviders: '',
        connectionTest: '',
        connectionTestURL: '',
        trackingMethod: '',
        communicationPlatform: '',
        autheticationDetails: '',
        eventUpdateURL: '',
        messageUpdateURL: '',
        secretKey: '',
        notificationDetails: '',
        secondTierTrackingProvider: '',
        supportEscalation: '',
        eldDisconnectionTime: '',
        driverApplicationTime: '',
        tokenEndpointUrl: '',
        locationProviderDisconnectionTime: '',
        authClientId: '',
        authClientSecret: '',
        authuserName: '',
        authuserPassword: ''
    };
    let [errorMessage, setErrorMessage] = useState<ErrorMessage>(initialErrorMessage);
    const initialOrganizationDetails: OrganizationDetails = {
        accountInformation: {
            companyId: 0,
            companyName: '',
            userName: '',
            businessEmailAddress: '',
            connectionStatusId: 0,
            connectionTestURL: '',
            phoneNumberExt: '',
            phoneNumber: '',
            companyStatus: {
                statusId: 0,
                status: ''
            },
            expiresOn: ''
        },
        configuration: {
            textLocateStartTimeId: "",
            textLocateEndTimeId: "",
            trackingProvidersId: 0,

            authenticationMethodId: 0,

            trackingMethodId: 0,
            communicationPlatformId: 0,
            autheticationDetails: {
                userName: '',
                password: '',
                clientId: '',
                clientSecretKey: ''
            },
            eventUpdateURL: '',
            messageUpdateURL: '',
            // secretKey: '',
            notificationDetailsId: 0,
            secondTierTrackingProviderId: 0,
            supportEscalationId: 0,
            eldDisconnectionTimeId: 0,
            driverApplicationTimeId: 0,
            tokenEndpointUrl: '',
            locationProviderDisconnectionTimeId: 0
        }
    };
    const initialConfigOptions: ConfigOptions = {
        trackingProviders: [],
        trackingMethod: [],
        communicationPlatform: [],
        notificationDetails: [],
        secondTierTrackingProviders: [],
        supportEscalations: [],
        eldDisconnectionTimes: [],
        driverApplicationTimes: [],
        locationProviderDisconnectionTimes: [],
        companyStatus: [],
        authenticationMethod: [],
        connectionStatus: []
    };
    const [organization3plDetails, setorganization3plDetails] = useState<OrganizationDetails>(initialOrganizationDetails);
    const [configurationData, setConfigurationData] = useState<ConfigOptions>(initialConfigOptions);
    const [connectionStatus, setConnectionStatus] = useState('online');
    const [somethingWentWrong, setsomethingWentWrong] = useState(false)
    const location = useLocation();
    const companyId: string = location?.state?.companyId
    const companyName: string = location?.state?.companyName
    useEffect(() => {
        // PS_FP_1.2-PS_FP_1.3 triggers useeffect with the empty dependency
        getConfigurationDetails().then(() => {
            if (companyId) {
                getOrganizationData(companyId);
            }
            setorganization3plDetails((prevDetails: any) => ({
                ...prevDetails,
                accountInformation: {
                    ...prevDetails.accountInformation,
                    phoneNumberExt: "1"  // Set the first tracking method ID
                }
            }));
        });
    }, []);
    const getConfigurationDetails = async () => {
        try {
            setLoader(true)
            const response = await retrieveConfigurationOptions();
            // PS_FP_1.5-PS_FP_1.6  - check for the response status,
            if (response.data.statusCode == 200) {
                setConfigurationData((prevDetail: any) => ({
                    ...prevDetail,
                    ...response.data.data
                }));
                configOption = response.data.data
                const companyStatus = configOption?.companyStatus?.find(platform => platform.status === 'Active');
                const authentication = configOption?.authenticationMethod?.find(platform => platform.authenticationMethods === 'Basic Authentication')
                if (authentication) {
                    setorganization3plDetails((prevDetails: any) => ({
                        ...prevDetails,
                        configuration: {
                            ...prevDetails.configuration,
                            authenticationMethodId: authentication.id //Set the first tracking method ID
                        }
                    }));
                }
                if (companyStatus) {
                    // PS_FP_1.7-PS_FP_1.8 and set values for the radioButton and checkboxes

                    setorganization3plDetails((prevDetails: any) => ({
                        ...prevDetails,
                        accountInformation: {
                            ...prevDetails.accountInformation,
                            companyStatus: {
                                statusId: companyStatus.id,
                                status: companyStatus.status
                            }  // Set the first tracking method ID
                        }
                    }));
                }
                const TruckMethod = configOption?.trackingMethod?.find(platform => platform.trackingMethod === 'Truck');


                if (TruckMethod) {
                    setorganization3plDetails((prevDetails: any) => ({
                        ...prevDetails,
                        configuration: {
                            ...prevDetails.configuration,
                            trackingMethodId: TruckMethod.id  // Set the first tracking method ID
                        }
                    }));
                }
                const emailPlatform = configOption?.communicationPlatform?.find(platform => platform.communicationPlatform === 'Email');
                if (emailPlatform) {

                    setorganization3plDetails((prevDetails: any) => ({
                        ...prevDetails,
                        configuration: {
                            ...prevDetails.configuration,
                            communicationPlatformId: emailPlatform.id // Set the first tracking method ID
                        }
                    }));
                }


            } else {
                setConfigurationData(initialConfigOptions);
            }
            setLoader(false)

        } catch (error) {
            setConfigurationData(initialConfigOptions);
            console.log('Error while fetching configuration details:', error);
            // Handle the error as needed
            setLoader(false)

        }
    };

    const getOrganizationData = async (companyId: string) => {
        // PS_FP_1.36-PS_FP_1.39
        // Implement retrieveOrganizationData logic
        try {
            setLoader(true)
            const response: any = await retrieveOrganizationData(companyId)
            if (response.data.statusCode == 200) {
                // PS_FP_1.7-PS_FP_1.8 set the response values to the variable
                const retreivedata: RetrieveData = response.data.data
                const decryptedData = decryptData(retreivedata.configuration.autheticationDetails)
                setFormData((prevData: any) => ({
                    ...prevData,
                    userName: retreivedata.configuration.autheticationDetails.userName,
                    clientId: retreivedata.configuration.autheticationDetails.clientId,
                    clientSecret: decryptedData.clientSecretKey,
                    password: decryptedData.password
                }));
                const authMethodId = configOption.authenticationMethod.find(authenticationMethod => authenticationMethod.authenticationMethods === 'Basic Authentication')?.id
                const companyStatusId = configOption.companyStatus.find(platform => platform.status === 'Active')?.id;
                const inactivestatusId = configOption.companyStatus.find(platform => platform.status === 'Inactive')?.id;
                const truckMethodId = configOption.trackingMethod.find(platform => platform.trackingMethod === 'Truck')?.id;
                const emailPlatformId = configOption?.communicationPlatform.find(platform => platform.communicationPlatform === 'Email')?.id;
                setorganization3plDetails((prevDetails: OrganizationDetails) => ({
                    ...prevDetails,
                    accountInformation: {
                        ...prevDetails.accountInformation,
                        companyId: retreivedata.accountInformation.companyId,
                        companyName: retreivedata.accountInformation.companyName,
                        userName: retreivedata.accountInformation.userName,
                        businessEmailAddress: retreivedata.accountInformation.businessEmailAddress,
                        phoneNumberExt: retreivedata.accountInformation.phoneNumberExt,
                        connectionTestURL: retreivedata.accountInformation.connectionTestURL,
                        connectionStatusId: retreivedata.accountInformation.connectionStatusId,
                        phoneNumber: retreivedata.accountInformation.phoneNumber,
                        companyStatus: {
                            statusId: retreivedata.accountInformation.companyStatusId !== inactivestatusId && retreivedata.accountInformation.companyStatusId !== companyStatusId
                                ? companyStatusId || 0 : retreivedata.accountInformation.companyStatusId !== 0
                                    ? retreivedata.accountInformation.companyStatusId || 0 : companyStatusId || 0,
                            status: retreivedata.accountInformation.companyStatus,
                        },
                        expiresOn: retreivedata.accountInformation.expiresOn,
                    },
                    configuration: {
                        ...prevDetails.configuration,
                        textLocateStartTimeId: retreivedata.configuration.textLocateStartTime,
                        textLocateEndTimeId: retreivedata.configuration.textLocateEndTime,
                        trackingProvidersId: retreivedata.configuration.trackingProviders,
                        authenticationMethodId: retreivedata.configuration.authenticationMethodId != 0 ? retreivedata.configuration.authenticationMethodId : authMethodId || 0,
                        trackingMethodId: retreivedata.configuration.trackingMethod !== 0 ? retreivedata.configuration.trackingMethod : truckMethodId || 0,
                        communicationPlatformId: retreivedata.configuration.communicationPlatform || emailPlatformId || 0,
                        autheticationDetails: {
                            userName: retreivedata.configuration.autheticationDetails.userName,
                            password: retreivedata.configuration.autheticationDetails.password,
                            clientId: retreivedata.configuration.autheticationDetails.clientId,
                            clientSecretKey: retreivedata.configuration.autheticationDetails.clientSecretKey,
                        },
                        eventUpdateURL: retreivedata.configuration.eventUpdateURL,
                        messageUpdateURL: retreivedata.configuration.messageUpdateURL,
                        notificationDetailsId: retreivedata.configuration.notificationDetails,
                        secondTierTrackingProviderId: retreivedata.configuration.secondTierTrackingProvider,
                        supportEscalationId: retreivedata.configuration.supportEscalation,
                        eldDisconnectionTimeId: retreivedata.configuration.eldDisconnectionTime,
                        driverApplicationTimeId: retreivedata.configuration.driverApplicationTime,
                        tokenEndpointUrl: retreivedata.configuration.tokenEndpointUrlId,
                        locationProviderDisconnectionTimeId: retreivedata.configuration.locationProviderDisconnection,
                    },
                }));
            }
            else {

                setsomethingWentWrong(true)
            }
            setLoader(false)
        }
        catch (error) {
            console.log("'The retrieve error", error)
            setsomethingWentWrong(true)
            setLoader(false)
        }
        // ...api call and handle the response
    };
    // PS_FP_1.4- invokeing the method to get the drop-down values
    const handleAuthChange = (e: any) => {
        setFormData({
            ...authDetails,
            [e.target.name]: e.target.value.trim()
        });
    };
    // PS_FP_1.12-PS_FP_1.17// Handle input change
    const handleInputChange = () => {
        setExistErrorMessage((prevError: any) => ({
            ...prevError,
            companyName: '',
            userName: ''
        }));
        if (!organization3plDetails.accountInformation.companyName) {
            errorMessage.companyName = 'Enter a value'
            setErrorMessage(prevError => ({
                ...prevError,
                companyName: errorMessage.companyName
            }));
        } else {
            errorMessage.companyName = ''
            setErrorMessage(prevError => ({
                ...prevError,
                companyName: errorMessage.companyName = ''
            }));
        }
        if (!organization3plDetails.accountInformation.userName) {
            errorMessage.userName = 'Enter a value'
            setErrorMessage(prevError => ({
                ...prevError,
                userName: errorMessage.userName = 'Enter a value'
            }));
        } else {
            errorMessage.userName = ''
            setErrorMessage(prevError => ({
                ...prevError,
                userName: errorMessage.userName
            }));
        }
        if (organization3plDetails.configuration.authenticationMethodId === configurationData.authenticationMethod.find(authenticationMethod => authenticationMethod.authenticationMethods === 'OAuth 2.0 Authentication')?.id) {
            errorMessage.authuserName = ''
            errorMessage.authuserPassword = ''
            setErrorMessage(prevError => ({
                ...prevError,
                authuserName: errorMessage.authuserName,
                authuserPassword: errorMessage.authuserPassword
            }));

            if (!authDetails.clientId) {
                errorMessage.authClientId = 'Enter a value'
                setErrorMessage(prevError => ({
                    ...prevError,
                    authClientId: errorMessage.authClientId
                }));
            } else {
                errorMessage.authClientId = ''
                setErrorMessage(prevError => ({
                    ...prevError,
                    authClientId: errorMessage.authClientId
                }));
            }
            if (!authDetails.clientSecret) {
                errorMessage.authClientSecret = 'Enter a value'
                setErrorMessage(prevError => ({
                    ...prevError,
                    authClientSecret: errorMessage.authClientSecret
                }));
            } else {
                errorMessage.authClientSecret = ''
                setErrorMessage(prevError => ({
                    ...prevError,
                    authClientSecret: errorMessage.authClientSecret
                }));
            }
            if (!organization3plDetails.configuration.tokenEndpointUrl) {
                errorMessage.tokenEndpointUrl = 'Enter a value'
                setErrorMessage(prevError => ({
                    ...prevError,
                    tokenEndpointUrl: errorMessage.tokenEndpointUrl
                }));
            } else if (!organization3plDetails.configuration.tokenEndpointUrl.startsWith('https://')) {
                errorMessage.tokenEndpointUrl = 'Please enter a valid URL'
                setErrorMessage(prevError => ({
                    ...prevError,
                    tokenEndpointUrl: errorMessage.tokenEndpointUrl
                }));
            }
            else {
                errorMessage.tokenEndpointUrl = ''
                setErrorMessage(prevError => ({
                    ...prevError,
                    tokenEndpointUrl: errorMessage.tokenEndpointUrl
                }));
            }
        }
        else {
            errorMessage.authClientSecret = ''
            errorMessage.authClientId = ''
            setErrorMessage(prevError => ({
                ...prevError,
                authClientId: errorMessage.authClientId,
                authClientSecret: errorMessage.authClientSecret
            }));

            if (!authDetails.userName) {
                errorMessage.authuserName = 'Enter a value'
                setErrorMessage(prevError => ({
                    ...prevError,
                    authuserName: errorMessage.authuserName
                }));
            } else {
                errorMessage.authuserName = ''
                setErrorMessage(prevError => ({
                    ...prevError,
                    authuserName: errorMessage.authuserName
                }));
            }
            if (!authDetails.password) {
                errorMessage.authuserPassword = 'Enter a value'
                setErrorMessage(prevError => ({
                    ...prevError,
                    authuserPassword: errorMessage.authuserPassword
                }));
            } else {
                errorMessage.authuserPassword = ''
                setErrorMessage(prevError => ({
                    ...prevError,
                    authuserPassword: errorMessage.authuserPassword
                }));
            }
        }
        if (!organization3plDetails.accountInformation.businessEmailAddress) {
            errorMessage.businessEmailAddress = 'Enter a value'
            setErrorMessage(prevError => ({
                ...prevError,
                businessEmailAddress: errorMessage.businessEmailAddress
            }));
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(organization3plDetails.accountInformation.businessEmailAddress)) {
            errorMessage.businessEmailAddress = 'Please enter a valid email address'
            setErrorMessage(prevError => ({
                ...prevError,
                businessEmailAddress: errorMessage.businessEmailAddress
            }));
        }
        else {
            errorMessage.businessEmailAddress = ''
            setErrorMessage(prevError => ({
                ...prevError,
                businessEmailAddress: errorMessage.businessEmailAddress
            }));
        }
        if (!organization3plDetails.accountInformation.phoneNumber) {

            errorMessage.phoneNumber = 'Enter a value'
            setErrorMessage(prevError => ({
                ...prevError,
                phoneNumber: errorMessage.phoneNumber
            }));
        } else if (organization3plDetails.accountInformation.phoneNumber.length < 8) {
            errorMessage.phoneNumber = 'Phone number must be at least 8 characters long'
            setErrorMessage(prevError => ({
                ...prevError,
                phoneNumber: errorMessage.phoneNumber
            }));
        }
        else {
            errorMessage.phoneNumber = ''
            setErrorMessage(prevError => ({
                ...prevError,
                phoneNumber: errorMessage.phoneNumber
            }));
        }
        if (!organization3plDetails.accountInformation.companyStatus.statusId) {
            errorMessage.companyStatus = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                companyStatus: errorMessage.companyStatus
            }));
        }
        else {
            errorMessage.companyStatus = ''
            setErrorMessage(prevError => ({
                ...prevError,
                companyStatus: errorMessage.companyStatus
            }));
        }
        if (organization3plDetails.accountInformation.companyStatus.status == "Active") {
            if (!organization3plDetails.accountInformation.expiresOn) {
                errorMessage.expiresOn = 'Enter a value'
                setErrorMessage(prevError => ({
                    ...prevError,
                    expiresOn: errorMessage.expiresOn
                }));
            }
            else {
                errorMessage.expiresOn = ''
                setErrorMessage(prevError => ({
                    ...prevError,
                    expiresOn: errorMessage.expiresOn
                }));
            }
        }
        if (!organization3plDetails.configuration.authenticationMethodId) {
            errorMessage.autheticationDetails = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                autheticationDetails: errorMessage.autheticationDetails
            }));
        }
        else {
            errorMessage.autheticationDetails = ''
            setErrorMessage(prevError => ({
                ...prevError,
                autheticationDetails: errorMessage.autheticationDetails
            }));
        }
        if (!organization3plDetails.configuration.communicationPlatformId) {
            errorMessage.communicationPlatform = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                communicationPlatform: errorMessage.communicationPlatform
            }));
        }
        else {
            errorMessage.communicationPlatform = ''
            setErrorMessage(prevError => ({
                ...prevError,
                communicationPlatform: errorMessage.communicationPlatform
            }));
        }
        if (!organization3plDetails.accountInformation.connectionTestURL) {
            errorMessage.connectionTestURL = 'Enter a value'
            setErrorMessage(prevError => ({
                ...prevError,
                connectionTestURL: errorMessage.connectionTestURL
            }));
        } else if (!organization3plDetails.accountInformation.connectionTestURL.startsWith('https://')) {
            errorMessage.connectionTestURL = 'Please enter a valid URL'
            setErrorMessage(prevError => ({
                ...prevError,
                connectionTestURL: errorMessage.connectionTestURL
            }));
        }
        else {
            errorMessage.connectionTestURL = ''
            setErrorMessage(prevError => ({
                ...prevError,
                connectionTestURL: errorMessage.connectionTestURL
            }));
        }
        if (!organization3plDetails.configuration.driverApplicationTimeId) {
            errorMessage.driverApplicationTime = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                driverApplicationTime: errorMessage.driverApplicationTime
            }));
        }
        else {
            errorMessage.driverApplicationTime = ''
            setErrorMessage(prevError => ({
                ...prevError,
                driverApplicationTime: errorMessage.driverApplicationTime
            }));
        }
        if (!organization3plDetails.configuration.eldDisconnectionTimeId) {
            errorMessage.eldDisconnectionTime = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                eldDisconnectionTime: errorMessage.eldDisconnectionTime
            }));
        }
        else {
            errorMessage.eldDisconnectionTime = ''
            setErrorMessage(prevError => ({
                ...prevError,
                eldDisconnectionTime: errorMessage.eldDisconnectionTime
            }));
        }
        if (!organization3plDetails.configuration.eventUpdateURL) {
            errorMessage.eventUpdateURL = 'Enter a value'
            setErrorMessage(prevError => ({
                ...prevError,
                eventUpdateURL: errorMessage.eventUpdateURL
            }));
        } else if (!organization3plDetails.configuration.eventUpdateURL.startsWith('https://')) {
            errorMessage.eventUpdateURL = 'Please enter a valid URL'
            setErrorMessage(prevError => ({
                ...prevError,
                eventUpdateURL: errorMessage.eventUpdateURL
            }));
        }
        else {
            errorMessage.eventUpdateURL = ''
            setErrorMessage(prevError => ({
                ...prevError,
                eventUpdateURL: errorMessage.eventUpdateURL
            }));
        }
        if (!organization3plDetails.configuration.locationProviderDisconnectionTimeId) {
            errorMessage.locationProviderDisconnectionTime = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                locationProviderDisconnectionTime: errorMessage.locationProviderDisconnectionTime
            }));
        }
        else {
            errorMessage.locationProviderDisconnectionTime = ''
            setErrorMessage(prevError => ({
                ...prevError,
                locationProviderDisconnectionTime: errorMessage.locationProviderDisconnectionTime
            }));
        }
        if (!organization3plDetails.configuration.messageUpdateURL) {
            errorMessage.messageUpdateURL = 'Enter a value'
            setErrorMessage(prevError => ({
                ...prevError,
                messageUpdateURL: errorMessage.messageUpdateURL
            }));
        } else if (!organization3plDetails.configuration.messageUpdateURL.startsWith('https://')) {
            errorMessage.messageUpdateURL = 'Please enter a valid URL'
            setErrorMessage(prevError => ({
                ...prevError,
                messageUpdateURL: errorMessage.messageUpdateURL
            }));
        }
        else {
            errorMessage.messageUpdateURL = ''
            setErrorMessage(prevError => ({
                ...prevError,
                messageUpdateURL: errorMessage.messageUpdateURL
            }));
        }
        if (!organization3plDetails.configuration.notificationDetailsId) {
            errorMessage.notificationDetails = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                notificationDetails: errorMessage.notificationDetails
            }));
        }
        else {
            errorMessage.notificationDetails = ''
            setErrorMessage(prevError => ({
                ...prevError,
                notificationDetails: errorMessage.notificationDetails
            }));
        }
        if (!organization3plDetails.configuration.trackingProvidersId) {
            errorMessage.trackingProviders = 'Select a value'

            setErrorMessage(prevError => ({
                ...prevError,
                trackingProviders: errorMessage.trackingProviders
            }));
        }
        else {
            errorMessage.trackingProviders = ''
            setErrorMessage(prevError => ({
                ...prevError,
                trackingProviders: errorMessage.trackingProviders
            }));
        }
        if (!organization3plDetails.configuration.secondTierTrackingProviderId) {
            errorMessage.secondTierTrackingProvider = 'Select a value'

            setErrorMessage(prevError => ({
                ...prevError,
                secondTierTrackingProvider: errorMessage.secondTierTrackingProvider
            }));
        }
        else {
            errorMessage.secondTierTrackingProvider = ''

            setErrorMessage(prevError => ({
                ...prevError,
                secondTierTrackingProvider: errorMessage.secondTierTrackingProvider
            }));
        }
        if (!organization3plDetails.configuration.supportEscalationId) {
            errorMessage.supportEscalation = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                supportEscalation: errorMessage.supportEscalation
            }));
        }
        else {
            errorMessage.supportEscalation = ''
            setErrorMessage(prevError => ({
                ...prevError,
                supportEscalation: errorMessage.supportEscalation
            }));
        }

        if (!organization3plDetails.configuration.textLocateStartTimeId) {
            errorMessage.textLocateStartTime = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                textLocateStartTime: errorMessage.textLocateStartTime
            }));
        }
        else {
            errorMessage.textLocateStartTime = ''
            setErrorMessage(prevError => ({
                ...prevError,
                textLocateStartTime: errorMessage.textLocateStartTime
            }));
        }
        if (!organization3plDetails.configuration.textLocateEndTimeId) {
            errorMessage.textLocateEndTime = 'Select a value'

            setErrorMessage(prevError => ({
                ...prevError,
                textLocateEndTime: errorMessage.textLocateEndTime
            }));
        }
        else {
            errorMessage.textLocateEndTime = ''
            setErrorMessage(prevError => ({
                ...prevError,
                textLocateEndTime: errorMessage.textLocateEndTime
            }));
        }
        if (!organization3plDetails.configuration.trackingMethodId) {
            errorMessage.trackingMethod = 'Select a value'
            setErrorMessage(prevError => ({
                ...prevError,
                trackingMethod: errorMessage.trackingMethod
            }));
        }
        else {
            errorMessage.trackingMethod = ''
            setErrorMessage(prevError => ({
                ...prevError,
                trackingMethod: errorMessage.trackingMethod
            }));
        }
        // PS_FP_1.24-PS_FP_1.25
    };
    // Logic for validating inputs when a user clicks submit
    const handleEndpointBlur = async (connectionTestURl: string) => {
        // Handling onBlur event for Endpoint URL Validation
        // PS_FP_1.19-PS_FP_1.21
        if (!connectionTestURl) {
            return; // Exit the function if the URL is empty
        }
        if (!connectionTestURl.startsWith('https://')) {
            return
        }

        let authData;
        if (organization3plDetails.configuration.authenticationMethodId === configurationData.authenticationMethod.find((authenticationMethod: { authenticationMethods: string; }) => authenticationMethod.authenticationMethods === 'Basic Authentication')?.id) {
            authData = "Basic";
        } else if (organization3plDetails.configuration.authenticationMethodId === configurationData.authenticationMethod.find((authenticationMethod: { authenticationMethods: string; }) => authenticationMethod.authenticationMethods === 'OAuth 2.0 Authentication')?.id) {
            authData = "OAuth";
        }
        const connectionOnline = configurationData?.connectionStatus?.find(platform => platform.status === 'Online');
        const connectionOffline = configurationData?.connectionStatus?.find(platform => platform.status === 'Offline');
        try {
            setLoader(true)

            const payload = {
                clientId: authDetails.clientId,
                clientSecret: authDetails.clientSecret,
                UserName: authDetails.userName,
                Password: authDetails.password,
                endpoint: connectionTestURl,
                authenticationId: authData,
            }


            // PS_FP_1.22-PS_FP_1.24
            const response = await ConnectionTest(payload);
            if (response.status == 200) {
                setConnectionStatus("online")
                setorganization3plDetails((prevState: { accountInformation: any; configuration: any; }) => ({
                    ...prevState,
                    accountInformation: {
                        ...prevState.accountInformation,
                        connectionStatusId: connectionOnline?.id,
                    },
                    configuration: prevState.configuration, // Make sure to retain other configuration properties
                }))

            } else {

                setConnectionStatus("offline")

                setorganization3plDetails((prevState: { accountInformation: any; configuration: any; }) => ({
                    ...prevState,
                    accountInformation: {
                        ...prevState.accountInformation,
                        connectionStatusId: connectionOffline?.id,
                    },
                    configuration: prevState.configuration, // Make sure to retain other configuration properties
                }))

                console.log("connectionURL", response)
            }
            setLoader(false)

        }
        catch (error) {
            setConnectionStatus("offline")
            setorganization3plDetails((prevState: { accountInformation: any; configuration: any; }) => ({
                ...prevState,
                accountInformation: {
                    ...prevState.accountInformation,
                    connectionStatusId: connectionOffline?.id,
                },
                configuration: prevState.configuration, // Make sure to retain other configuration properties
            }))
            console.log("connectionURLERR", error)

        }
        setLoader(false)
    };
    const handleSubmit = async () => {

        handleInputChange()
        const hasError = Object.values(errorMessage).every(error => error == '');
        if (!hasError) {
            // If there are error messages, do not proceed with the API call
            return;
        }
        const encryptedFormData = encryptData(authDetails);
        const OrganizationPayload = { ...organization3plDetails }
        OrganizationPayload.configuration.autheticationDetails.userName = authDetails.userName
        OrganizationPayload.configuration.autheticationDetails.password = encryptedFormData.password
        OrganizationPayload.configuration.autheticationDetails.clientId = authDetails.clientId
        OrganizationPayload.configuration.autheticationDetails.clientSecretKey = encryptedFormData.clientSecret
        try {
            setLoader(true)
            const response: any = await post3plForm(OrganizationPayload);
            // PS_FP_1.29-PS_FP_1.33 checkfor the response
            if (response.data.statusCode == 200) {
                if (OrganizationPayload.accountInformation.companyId == 0) {
                    navigate('/3pldetails', { state: { openToastMessage: true, action: "create" } });
                }
                else {
                    navigate('/3pldetails', { state: { openToastMessage: true, action: "update" } });

                }
            }
            else if (response.data.statusCode == 400 && response.data.statusMessage == "Company name already exists") {
                setExistErrorMessage((prevError: any) => ({
                    ...prevError,
                    companyName: response.data.statusMessage
                }));
            }
            else if (response.data.statusCode == 400 && response.data.statusMessage == "Email Address already exists") {
                setExistErrorMessage((prevError: any) => ({
                    ...prevError,
                    userName: response.data.statusMessage
                }));
            }
            else {
                setsomethingWentWrong(true)
            }
            setLoader(false)

            // Check response and navigate or display appropriate error/warning
        } catch (error) {
            setsomethingWentWrong(true)
            console.log("the error logging", error)
            setLoader(false)
        }
    };

    // PS_FP_1.34-clicks cancel button clear the data and navigate it
    const handleCancel = () => {
        setorganization3plDetails(initialOrganizationDetails); // or desired initial state
        navigate('/3pldetails');
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const startTime = organization3plDetails.configuration.textLocateStartTimeId;
        const endTime = organization3plDetails.configuration.textLocateEndTimeId;
    
        // Validate the start and end times based on the input field changed
        if (name === "textLocateStartTimeId" && value >= endTime && endTime) {
            setErrorMessage(prevError => ({
                ...prevError,
                textLocateStartTime: "Start time should not be greater than or equal to end time",
                textLocateEndTime: "", // Clear error for end time if any
            }));
            return; // Don't update state if invalid
        } else if (name === "textLocateEndTimeId" && value <= startTime && startTime) {
            setErrorMessage(prevError => ({
                ...prevError,
                textLocateEndTime: "End time should not be less than or equal to start time",
                textLocateStartTime: "", // Clear error for start time if any
            }));
            return; // Don't update state if invalid
        }
    
        // Clear any error messages if the input is valid
        setErrorMessage(prevError => ({
            ...prevError,
            textLocateStartTime: "",
            textLocateEndTime: "",
        }));
    
        // Update state with the new valid time
        setorganization3plDetails((prevState) => ({
            ...prevState,
            configuration: {
                ...prevState.configuration,
                [name]: value, // Parse and set to 0 if parsing fails
            },
            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
        }))
    };

    return (
        <>
            {
                loader && <Loader />
            }
            {
                somethingWentWrong && <SomethingWentWrong onClose={() => setsomethingWentWrong(false)} />
            }
            {connectionStatus === "offline" && (
                <div
                    className="modal fade show"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "block", paddingLeft: 0, backgroundColor: "rgba(0, 0, 0, .5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content secondary-bg-color">
                            <div className="modal-body pt-4">
                                <div className="row text-center justify-content-center">
                                    <img
                                        src="images/alert-icon.svg"
                                        alt="alert-icon"
                                        className="alert-icon p-0"
                                    />
                                    <h1 className="primary-header mt-3" id="exampleModalLabel">
                                        Alert
                                    </h1>
                                    <p className="form-text-color font-16 font-regular">
                                        Connection test failed. Please verify the provided URL and system configuration.                                    </p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center pb-4 pt-0 border-0">
                                <button
                                    onClick={() => setConnectionStatus("Offline")}
                                    type="button"
                                    className="btn primary-btn font-semibold px-4"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-12 py-4 px-5">
                    <h5 className="font-13 font-regular top-nav-label-dark mb-4">
                        <span className="top-nav-label" >
                            <span style={{ cursor: 'pointer' }} onClick={handleCancel}>
                                3PL
                            </span>
                            <img
                                src="images/next-icon-grey.svg"
                                alt="next-icon-grey"
                                className="pb-1 mx-2"
                            />
                        </span>
                        {companyId ? companyName : 'New Logistic Partner'}
                    </h5>
                    <div className="p-4 primary-shadow primary-bg-color rounded-3">
                        <div className="pb-4 mb-2">
                            <h5 className="color-black-v1 font-22 font-bold m-0 d-flex align-items-center">
                                <a className="text-decoration-none custom-label back-btn">
                                    <img
                                        src="images/left-arrow.svg"
                                        alt="left-arrow"
                                        className="me-3 close-img"
                                        style={{ cursor: 'pointer' }} onClick={handleCancel}
                                    />
                                </a>
                                {companyId ? companyName : 'New Logistic Partner'}
                            </h5>
                        </div>
                        <div className="row">
                            <h5 className="font-18 font-semibold custom-label pb-2">
                                Account Information
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="CompanyName"
                                >
                                    Company Name
                                </label>
                                {!companyId && <span className="required-color">*</span>}
                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color"
                                    id="CompanyName"
                                    maxLength={64}
                                    // PS_FP_1.40- disable the button
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                    disabled={companyId !== undefined}
                                    placeholder="Enter Company Name"
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            accountInformation: {
                                                ...prevState.accountInformation,
                                                companyName: e.target.value.trim(),
                                            },
                                            configuration: prevState.configuration, // Make sure to retain other configuration properties
                                        }))
                                    }
                                    value={organization3plDetails.accountInformation.companyName}
                                />
                                {!existErrorMessage.companyName && (
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{errorMessage.companyName}</label>
                                    </div>
                                )}
                                {existErrorMessage.companyName && (
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{existErrorMessage.companyName}</label>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="Username"
                                >
                                    Username
                                </label>
                                {!companyId && <span className="required-color">*</span>}
                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color "
                                    id="Username"
                                    maxLength={64}
                                    // PS_FP_1.40- disable the button
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                    disabled={companyId !== undefined}
                                    placeholder="Enter Username"
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            accountInformation: {
                                                ...prevState.accountInformation,
                                                userName: e.target.value.trim(),
                                            },
                                            configuration: prevState.configuration, // Make sure to retain other configuration properties
                                        }))
                                    }
                                    value={organization3plDetails.accountInformation.userName}
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.userName}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="EmailAddress"
                                >
                                    Business Email Address
                                </label>
                                {!companyId && <span className="required-color">*</span>}
                                <input
                                    type="text"
                                    className="form-control custom-form-input primary-bg-color primary-border-color custom-input"
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                    id="EmailAddress"
                                    disabled={companyId !== undefined}
                                    placeholder="Enter Business Email Address"
                                    maxLength={64}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            accountInformation: {
                                                ...prevState.accountInformation,
                                                businessEmailAddress: e.target.value.trim(),
                                            },
                                            configuration: prevState.configuration, // Make sure to retain other configuration properties
                                        }))
                                    }
                                    value={organization3plDetails.accountInformation.businessEmailAddress}
                                />
                                {!existErrorMessage.userName && (
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{errorMessage.businessEmailAddress}</label>
                                    </div>
                                )}
                                {existErrorMessage.userName && (
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{existErrorMessage.userName}</label>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="PhoneNumber"
                                >
                                    Phone Number
                                    <span className="required-color">*</span>
                                </label>
                                <div className="input-group mb-3">
                                    <select
                                        className="form-select pe-3 custom-form-input custom-select-number primary-bg-color primary-border-color"
                                        aria-label="Default select example"
                                        value={organization3plDetails.accountInformation.phoneNumberExt}
                                        onChange={(e) =>
                                            setorganization3plDetails((prevState) => ({
                                                ...prevState,
                                                accountInformation: {
                                                    ...prevState.accountInformation,
                                                    phoneNumberExt: (e.target.value), // Parse and set to 0 if parsing fails
                                                },
                                                configuration: prevState.configuration, // Make sure to retain other configuration properties
                                            }))
                                        }                  >
                                        {/* PS_FP_1.11-set the US values to the phoneExt */}
                                        {all.filter(country => country.country === 'United States').map((country, index) => (

                                            <option key={index} value={country.countryCodes}>
                                                {country.countryCodes}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="text"
                                        className="form-control custom-form-input primary-bg-color primary-border-color"
                                        id="PhoneNumber"
                                        placeholder="Enter Phone Number"
                                        maxLength={15}
                                        onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                        onChange={(e) =>
                                            setorganization3plDetails((prevState) => ({
                                                ...prevState,
                                                accountInformation: {
                                                    ...prevState.accountInformation,
                                                    phoneNumber: e.target.value.trim(),
                                                },
                                                configuration: prevState.configuration, // Make sure to retain other configuration properties
                                            }))
                                        }
                                        value={organization3plDetails.accountInformation.phoneNumber}
                                    />
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.phoneNumber}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="Status"
                                >
                                    Status
                                </label>
                                <div className="d-flex align-items-center gap-4 py-2">
                                    {configurationData.companyStatus.filter(status => status.status === 'Active' || status.status === 'Inactive')
                                        .map(status => (
                                            <div key={status.id} className="form-check d-flex align-items-center gap-2 p-0">
                                                <input
                                                    className="form-check-input primary-bg-color m-0 form-checked primary-border-color"
                                                    type="radio"
                                                    style={{ cursor: 'pointer' }}
                                                    name="StatuRadio"
                                                    id={`${status.id}`}
                                                    defaultChecked={status.status === 'Active'}
                                                    checked={organization3plDetails.accountInformation.companyStatus.statusId === status.id}
                                                    onChange={(e) =>
                                                        setorganization3plDetails((prevState) => ({
                                                            ...prevState,
                                                            accountInformation: {
                                                                ...prevState.accountInformation,
                                                                companyStatus: {
                                                                    ...prevState.accountInformation.companyStatus,
                                                                    statusId: status.id,
                                                                    status: status.status,
                                                                },
                                                            },
                                                            configuration: prevState.configuration, // Make sure to retain other configuration properties
                                                        }))
                                                    }
                                                    value={organization3plDetails.accountInformation.companyStatus.status}
                                                // checked={organization3plDetails.accountInformation.companyStatus.statusId === status.id}


                                                />
                                                <label
                                                    className="form-check-label font-regular form-text-color font-14"
                                                    htmlFor={status.status}
                                                >
                                                    {status.status}
                                                </label>

                                            </div>
                                        ))}

                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.companyStatus}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 className="font-18 font-semibold custom-label pb-2">
                                API Key Validity
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="ExpiresOn"
                                >
                                    Expires On
                                    {organization3plDetails.accountInformation.companyStatus.status !== 'Inactive' && <span className="required-color">*</span>}
                                </label>
                                <div className="position-relative">
                                    <input
                                        type="date"
                                        className="form-control custom-form-input primary-bg-color custom-form  primary-border-color font-regular py-2 helper-text"
                                        id="ExpiresOn"
                                        max="9999-12-31"
                                        placeholder="Enter New Password"
                                        onChange={(e) =>
                                            setorganization3plDetails((prevState) => ({
                                                ...prevState,
                                                accountInformation: {
                                                    ...prevState.accountInformation,
                                                    expiresOn: e.target.value,
                                                },
                                                configuration: prevState.configuration, // Make sure to retain other configuration properties
                                            }))
                                        }
                                        value={organization3plDetails.accountInformation.expiresOn}
                                    />
                                    {/* <img
                                        className="position-absolute custom-input-icon"
                                        src="images/calander-icon.svg"
                                        alt="calander-icon"
                                    /> */}
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.expiresOn}</label>
                                </div>
                            </div>

                        </div>
                        <div className="row mb-3">
                            <h5 className="font-18 font-semibold custom-label pb-2">
                                Configuration
                            </h5>

                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label className="font-semibold form-text-color font-14 pb-1" htmlFor="PreferredVendor">
                                    Preferred Tracking Provider <span className="required-color">*</span>
                                </label>
                                <select style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={organization3plDetails.configuration.trackingProvidersId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                trackingProvidersId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
                                        }))
                                    }
                                >
                                    <option >Select</option>
                                    {configurationData.trackingProviders.map(provider => (
                                        <option key={provider.id} value={provider.id}>
                                            {provider.trackingVendor}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.trackingProviders}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="TrackingMethod"
                                >
                                    Tracking Method
                                </label>
                                <div className="d-flex align-items-center gap-4 py-2">
                                    {configurationData.trackingMethod.map(method => (
                                        <div key={`${method.id}`} className="form-check d-flex align-items-center gap-2 p-0">
                                            <input
                                                className="form-check-input primary-bg-color m-0 form-checked primary-border-color"
                                                type="radio"
                                                style={{ cursor: 'pointer' }}
                                                name="TrackingRadio"
                                                id={`${method.id}`}
                                                checked={organization3plDetails.configuration.trackingMethodId === method.id}
                                                defaultChecked={method.trackingMethod === 'Truck'}
                                                onChange={() =>
                                                    setorganization3plDetails((prevState) => ({
                                                        ...prevState,
                                                        configuration: {
                                                            ...prevState.configuration,
                                                            trackingMethodId: method.id, // Set tracking method ID to the selected method's ID
                                                            // Other configuration properties remain unchanged
                                                        },
                                                    }))
                                                }
                                            />
                                            <label
                                                className="form-check-label   font-regular form-text-color font-14"
                                                htmlFor={method.trackingMethod}
                                            >
                                                {method.trackingMethod}
                                            </label>

                                        </div>
                                    ))}
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.trackingMethod}</label>

                                </div>
                            </div>
                            {/* PS_FP_1.9-set the communicatinoPlatform as defautl */}
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="CommunicationPlatform"
                                >
                                    Communication Platform <span className="required-color">*</span>
                                </label>
                                <div className="py-2">
                                    {/* <div class="d-flex align-items-center gap-4 py-2 pe-4"> */}
                                    <div>
                                        {configurationData.communicationPlatform.map((platform: any) => (
                                            <div key={platform.id} className="form-check form-check-inline me-3 me-md-4 align-middle">
                                                <input
                                                    style={{ cursor: 'pointer' }}
                                                    className="form-check-input primary-bg-color form-checkbox primary-border-color"
                                                    type="checkbox"
                                                    id={`${platform.id}`}
                                                    defaultChecked={platform.communicationPlatform == 'Email'}// Adjust defaultChecked as per your requirement
                                                    // defaultChecked={platform.communicationPlatform =='Email'?true:false}
                                                    disabled={platform.communicationPlatform !== 'Email'}
                                                    onChange={(e: any) =>
                                                        setorganization3plDetails((prevState) => ({
                                                            ...prevState,
                                                            configuration: {
                                                                ...prevState.configuration,
                                                                communicationPlatformId: e.target.checked ? platform.id : 0, // Set to empty string if unchecked
                                                                // Other configuration properties remain unchanged
                                                            },
                                                        }))
                                                    }
                                                    value={organization3plDetails.configuration.communicationPlatformId}

                                                />
                                                <label
                                                    className="form-check-label font-regular form-text-color font-14"
                                                    htmlFor={`${platform.id}`}
                                                >                                                    {platform.communicationPlatform}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.communicationPlatform}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 className="font-18 font-semibold custom-label pb-2">
                                Authentication
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="PreferredVendor"
                                >
                                    Authentication Method <span className="required-color">*</span>
                                </label>
                                <select
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    style={{ cursor: 'pointer' }}
                                    value={organization3plDetails.configuration.authenticationMethodId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                authenticationMethodId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Retain other configuration properties
                                        }))
                                    }
                                >
                                    {configurationData.authenticationMethod.map((method) => (
                                        <option key={method.id} value={method.id}>
                                            {method.authenticationMethods}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.autheticationDetails}</label>
                                </div>
                            </div>
                            {/* PS_FP_1.10-authenticationMethod ternary check */}
                            {organization3plDetails.configuration.authenticationMethodId === configurationData.authenticationMethod.find(authenticationMethod => authenticationMethod.authenticationMethods === 'OAuth 2.0 Authentication')?.id ? (
                                <><div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                    <label className="font-semibold form-text-color font-14 pb-1" htmlFor="Username">
                                        Client ID<span className="required-color">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        className="form-control custom-form-input primary-bg-color primary-border-color"
                                        id="clientId"
                                        placeholder="Enter Client ID"

                                        onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                        name="clientId"
                                        onChange={handleAuthChange}
                                        value={authDetails.clientId}
                                    />
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{errorMessage.authClientId}</label>
                                    </div>
                                </div><div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                        <label
                                            htmlFor="password"
                                            className="form-label font-semibold form-text-color font-14 mb-1"
                                        >
                                            Client Secret <span className="required-color">*</span>
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type={showPass == 'password' ? 'password' : 'text'} className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                                id="clientSecret"
                                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                                placeholder="Enter Client Secret"
                                                name="clientSecret"
                                                onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                onCut={(e) => e.preventDefault()}
                                                onDrag={(e) => e.preventDefault()}
                                                onDrop={(e) => e.preventDefault()}
                                                onCopy={(e) => e.preventDefault()}
                                                onChange={handleAuthChange}
                                                value={authDetails.clientSecret}
                                            />
                                            {showPass == 'password' ? (<span style={{ cursor: 'pointer' }} className="position-absolute custom-input-eye-postion custom-input-eye-color eye-close" onClick={() => { setShowPass('text'); }} />
                                            ) : (<span style={{ cursor: 'pointer' }} className="position-absolute eye-open-icon custom-input-eye-color eye-open" onClick={() => { setShowPass('password'); }} />)}
                                        </div>
                                        <div className="pt-1">
                                            <label className="required-color font-12 font-regular">{errorMessage.authClientSecret}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                        <label
                                            className="font-semibold form-text-color font-14 pb-1"
                                            htmlFor="TokenEndpointUrl"
                                        >
                                            Token Endpoint URL
                                        </label>

                                        <img
                                            className="ms-2 tooltip-img"
                                            src="images/tooltip-icon.svg"
                                            alt="tooltip-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-original-title="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                            aria-label="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                        />
                                        <span className="required-color">*</span>
                                        <input
                                            type="url"
                                            className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                            id="EventUpdateURL"
                                            onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                            placeholder="Enter Event Update URL"
                                            value={organization3plDetails.configuration.tokenEndpointUrl}
                                            onChange={(e) =>
                                                setorganization3plDetails((prevState) => ({
                                                    ...prevState,
                                                    configuration: {
                                                        ...prevState.configuration,
                                                        tokenEndpointUrl: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                        // Other configuration properties remain unchanged
                                                    },
                                                }))
                                            }
                                        />
                                        <div className="pt-1">
                                            <label className="required-color font-12 font-regular">{errorMessage.tokenEndpointUrl}</label>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <><div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                    <label className="font-semibold form-text-color font-14 pb-1" htmlFor="Username">
                                        Username <span className="required-color">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        className="form-control custom-form-input primary-bg-color primary-border-color"
                                        id="Username"
                                        onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                        placeholder="Enter Username"
                                        name="userName"
                                        onChange={handleAuthChange}
                                        value={authDetails.userName}

                                    />
                                    <div className="pt-1">
                                        <label className="required-color font-12 font-regular">{errorMessage.authuserName}</label>
                                    </div>
                                </div><div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                        <label
                                            htmlFor="password"
                                            className="form-label font-semibold form-text-color font-14 mb-1"
                                        >
                                            Password <span className="required-color">*</span>
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type={showPass == 'password' ? 'password' : 'text'} className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                                id="password"
                                                placeholder="Enter Password"
                                                onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                onCut={(e) => e.preventDefault()}
                                                onDrag={(e) => e.preventDefault()}
                                                onDrop={(e) => e.preventDefault()}
                                                onCopy={(e) => e.preventDefault()}
                                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                                name="password"
                                                onChange={handleAuthChange}
                                                value={authDetails.password}
                                            />
                                            {showPass == 'password' ? (<span style={{ cursor: 'pointer' }} className="position-absolute custom-input-eye-postion custom-input-eye-color eye-close" onClick={() => { setShowPass('text'); }} />
                                            ) : (<span style={{ cursor: 'pointer' }} className="position-absolute eye-open-icon custom-input-eye-color eye-open" onClick={() => { setShowPass('password'); }} />)}
                                        </div>
                                        <div className="pt-1">
                                            <label className="required-color font-12 font-regular">{errorMessage.authuserPassword}</label>
                                        </div>
                                    </div></>
                            )}

                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="EventUpdateURL"
                                >
                                    Event Update URL
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip" // Enable Bootstrap tooltip
                                    data-bs-placement="top" // Set tooltip placement
                                    title="Specify the Webhook URL where event updates on loads should be sent." // Set tooltip message
                                    aria-label="Tooltip icon"
                                    aria-describedby="tooltip89429" // Optional: Provide an ID if you want to associate it with a specific element
                                />
                                <span className="required-color">*</span>
                                <input
                                    type="url"
                                    className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                    id="EventUpdateURL"
                                    placeholder="Enter Event Update URL"
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                    value={organization3plDetails.configuration.eventUpdateURL}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                eventUpdateURL: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.eventUpdateURL}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="EventUpdateURL"
                                >
                                    Message Update URL
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the Webhook URL where message updates from the driver should be sent."
                                    aria-label="Specify the Webhook URL where message updates from the driver should be sent."
                                />
                                <span className="required-color">*</span>
                                <input
                                    type="url"
                                    className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                    id="EventUpdateURL"
                                    placeholder="Enter Event Update URL"
                                    value={organization3plDetails.configuration.messageUpdateURL}
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                messageUpdateURL: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.messageUpdateURL}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="ConnectionTestURL"
                                >
                                    Connection Test URL
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the URL that the system will access to perform a connection test or health check."
                                    aria-label="Specify the URL that the system will access to perform a connection test or health check."
                                />
                                <span className="required-color">*</span>
                                <input
                                    type="url"
                                    className="form-control custom-form-input primary-bg-color primary-border-color helper-text"
                                    id="ConnectionTestURL"
                                    placeholder="Enter Connection Test URL"
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                    value={organization3plDetails.accountInformation.connectionTestURL}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            accountInformation: {
                                                ...prevState.accountInformation,
                                                connectionTestURL: e.target.value.trim(), // Set tracking method ID to the selected method's ID
                                                // Other configuration properties remain unchanged
                                            },
                                        }))
                                    }
                                    onBlur={() => handleEndpointBlur(organization3plDetails.accountInformation.connectionTestURL)}
                                />
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.connectionTestURL}</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 className="font-18 font-semibold custom-label pb-4 m-0">
                                Tracking Timeline Escalation
                            </h5>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="NotificationDetail"
                                >
                                    Notification Detail Hrs
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the number of hours before pick-up to notify if tracking details are unavailable."
                                    aria-label="Specify the number of hours before pick-up to notify if tracking details are unavailable."
                                />
                                <span className="required-color">*</span>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={organization3plDetails.configuration.notificationDetailsId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                notificationDetailsId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {configurationData.notificationDetails.map((hour, index) => (
                                        <option key={index} value={`${hour.id}`}>
                                            {hour.notificationDetailHrs}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.notificationDetails}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="SecondTierTracking"
                                >
                                    Second Tier Tracking Provider Hrs
                                    <img
                                        className="ms-2 tooltip-img"
                                        src="images/tooltip-icon.svg"
                                        alt="tooltip-icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title=""
                                        data-bs-original-title="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                        aria-label="Specify the number of hours before
                              the pickup time to escalate the load to the location provider if the visibility provider is unresponsive."
                                    />{" "}
                                    <span className="required-color">*</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={organization3plDetails.configuration.secondTierTrackingProviderId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                secondTierTrackingProviderId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {configurationData.secondTierTrackingProviders.map((hour, index) => (
                                        <option key={index} value={`${hour.id}`}>
                                            {hour.secondTierTrackingProviderHrs}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.secondTierTrackingProvider}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="SupportEscalation"
                                >
                                    Support Escalation Hrs
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the number of hours after
                              the pickup time to escalate to support staff if updates from tracking providers are not received."
                                    aria-label="Specify the number of hours after
                              the pickup time to escalate to support staff if updates from tracking providers are not received."
                                />
                                <span className="required-color">*</span>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={organization3plDetails.configuration.supportEscalationId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                supportEscalationId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {configurationData.supportEscalations.map((hour, index) => (
                                        <option key={index} value={`${hour.id}`}>
                                            {hour.supportEscalationHrs}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.supportEscalation}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-2"
                                    htmlFor="TextLocate"
                                >
                                    Text Locate Quiet Hrs
                                </label>
                                <img
                                    className="ms-2 tooltip-img"
                                    src="images/tooltip-icon.svg"
                                    alt="tooltip-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title=""
                                    data-bs-original-title="Specify the quiet hours for drivers by defining the start and end times during which bulk messages won't be sent."
                                    aria-label="Specify the quiet hours for drivers by defining the start and end times during which bulk messages won't be sent."
                                />
                                <span className="required-color">*</span>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4 pe-2">
                                        <label
                                            className="font-semibold form-text-color font-14 pb-1"
                                            htmlFor="StartTime"
                                        >
                                            Start Time
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type="time"
                                                className="form-control custom-form-input primary-bg-color custom-form primary-border-color font-regular py-2 helper-text"
                                                id="StartTime"
                                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                                name='textLocateStartTimeId'
                                                value={organization3plDetails.configuration.textLocateStartTimeId}
                                                onChange={(e) =>handleDateChange(e)}
                                            />
                                            <img
                                                className="position-absolute custom-input-icon"
                                                src="images/clock-icon.svg"
                                                alt="clock-icon"
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                        <div className="pt-1">
                                            <label className="required-color font-12 font-regular">{errorMessage.textLocateStartTime}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4 pe-2">
                                        <label
                                            className="font-semibold form-text-color font-14 pb-1"
                                            htmlFor="EndTime"
                                        >
                                            End Time
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type="time"
                                                name="textLocateEndTimeId"
                                                className="form-control custom-form-input primary-bg-color custom-form  primary-border-color font-regular py-2 helper-text"
                                                id="EndTime"
                                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                                value={organization3plDetails.configuration.textLocateEndTimeId}
                                                onChange={(e) =>handleDateChange(e)}
                                            />
                                            <img
                                                className="position-absolute custom-input-icon"
                                                src="images/clock-icon.svg"
                                                alt="clock-icon"
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <div className="pt-1">
                                                <label className="required-color font-12 font-regular">{errorMessage.textLocateEndTime}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <h5 className="font-18 font-semibold custom-label">
                                Tracking Disconnection Escalation
                            </h5>
                            <p className="font-regular font-14 helper-text">
                                Specify the interval for escalating the source when tracking is
                                disconnected
                            </p>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="font-semibold form-text-color font-14 pb-1"
                                    htmlFor="ELDHrs"
                                >
                                    ELD Hrs <span className="required-color">*</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={organization3plDetails.configuration.eldDisconnectionTimeId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                eldDisconnectionTimeId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {configurationData.eldDisconnectionTimes.map((hour, index) => (
                                        <option key={index} value={`${hour.id}`}>
                                            {hour.eldHrs}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.eldDisconnectionTime}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="  font-semibold form-text-color font-14 pb-1"
                                    htmlFor="DriverApplicationHrs"
                                >
                                    Driver Application Hrs <span className="required-color">*</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={organization3plDetails.configuration.driverApplicationTimeId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                driverApplicationTimeId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {configurationData.driverApplicationTimes.map((hour, index) => (
                                        <option key={index} value={`${hour.id}`}>
                                            {hour.driverApplicationHrs}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.driverApplicationTime}</label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mb-4 pe-4">
                                <label
                                    className="  font-semibold form-text-color font-14 pb-1"
                                    htmlFor="LocationProviderHrs"
                                >
                                    Location Provider Hrs <span className="required-color">*</span>
                                </label>
                                <select
                                    style={{ cursor: 'pointer' }}
                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color helper-text"
                                    aria-label="Default select example"
                                    value={organization3plDetails.configuration.locationProviderDisconnectionTimeId}
                                    onChange={(e) =>
                                        setorganization3plDetails((prevState) => ({
                                            ...prevState,
                                            configuration: {
                                                ...prevState.configuration,
                                                locationProviderDisconnectionTimeId: parseInt(e.target.value) || 0, // Parse and set to 0 if parsing fails
                                            },
                                            accountInformation: prevState.accountInformation, // Make sure to retain other configuration properties
                                        }))
                                    }
                                >
                                    <option>Select</option>
                                    {configurationData.locationProviderDisconnectionTimes.map((hour, index) => (
                                        <option key={index} value={`${hour.id}`}>
                                            {hour.locationProviderHrs}
                                        </option>
                                    ))}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{errorMessage.locationProviderDisconnectionTime}</label>
                                </div>
                            </div>

                        </div>
                        <div className="text-end mb-3 pe-2">
                            <button type="button" className="btn text-btn font-semibold me-3" onClick={handleCancel}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn primary-btn font-semibold px-3"
                                onClick={handleSubmit}
                                onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}
// Export the form component
export default ThreePLForm;


