

import CryptoJS from 'crypto-js';
import { RoleEnum } from "../enums/enums";
import { RetrieveAuthDetails, credentials } from '../interface/Interface';
import { checkAPIKeyValidationandConfig, insertLoginAudits, retrieveUserDetails } from "../service/Api";



export const encryptData = (data: credentials) => {
    const encryptedData = { ...data };
    // Check if clientSecret is present
    if (encryptedData.clientSecret) {
        const encryptedMessage = CryptoJS.AES.encrypt(encryptedData.clientSecret, process.env.REACT_APP_SECRETKEY||'').toString();
        encryptedData.clientSecret = encryptedMessage;
    }

    // Check if password is present
    if (encryptedData.password) {
        const encryptedMessage = CryptoJS.AES.encrypt(encryptedData.password, process.env.REACT_APP_SECRETKEY||'').toString();
        encryptedData.password = encryptedMessage;
    }
    return encryptedData;
}
export const decryptData = (data: RetrieveAuthDetails) => {
    const decryptedData = { ...data };
    // Check if clientSecret is present
    if (decryptedData.clientSecretKey) {
        const bytes = CryptoJS.AES.decrypt(decryptedData.clientSecretKey, process.env.REACT_APP_SECRETKEY||'');
        const decryptedMessage = bytes.toString(CryptoJS.enc.Utf8);
        decryptedData.clientSecretKey = decryptedMessage;

    }

    // Check if password is present
    if (decryptedData.password) {
        const bytes = CryptoJS.AES.decrypt(decryptedData.password, process.env.REACT_APP_SECRETKEY||'');
        const decryptedMessage = bytes.toString(CryptoJS.enc.Utf8);
        decryptedData.password = decryptedMessage;
    }
    return decryptedData;


}


export const isValidEmail = (email : string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


export async function postLoginAPIs(groups: any) {
    try {
        if (groups) {
            if (groups[0] !== RoleEnum.ETM_ADMIN) {
                //PC_SI_42-PC_SI_46-Method to check API key and config complete check
                let apiKeyResponse = await checkAPIKeyValidationandConfig();
                if (apiKeyResponse?.status == 200) {
                    sessionStorage.setItem("isAPIKeyValid", apiKeyResponse?.data?.data[0]?.APIKeyValid)
                    sessionStorage.setItem("isConfigurationComplete", apiKeyResponse?.data?.data[0]?.ConfigurationCompleted)
                }
            }
        }
    } catch (error) {
        console.log(error);
    }

    try {
        //PC_SI_30-PC_SI_41-Method to retrieve user details 
        let response = await retrieveUserDetails();
        console.log(response);
        if (response?.status == 200) {
            localStorage.setItem("themeId", response?.data?.data?.theme?.themeId)
            localStorage.setItem("theme", response?.data?.data?.theme?.themeName)
            sessionStorage.setItem("companyId", response?.data?.data?.company?.companyId)
            sessionStorage.setItem("company", response?.data?.data?.company?.companyName)
            sessionStorage.setItem("username", response?.data?.data?.user?.userName)
        }

    } catch (error) {
        console.log(error);
    }
    try {
        //PC_SI_46-PC_SI_50-Method to insert logs
        let logResponse = await insertLoginAudits();
        if (logResponse?.status != 200) {
            console.log(logResponse);
        }
    } catch (error) {
        console.log(error);
    }

}