import { useState, useEffect } from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'; // Assuming you'll use this later
import { useNavigate, useLocation } from 'react-router';
import { jwtDecode } from "jwt-decode";
import { RoleEnum, Status } from '../enums/enums';
import { updateInvitationStatus } from '../service/Api';
import { ChildComponentProps } from "../interface/Interface"
import { postLoginAPIs } from '../utils/utils';

export const ResetPasswordComponent: React.FC<ChildComponentProps> = ({ showLoader, hideLoader }) => {
    //PC_RP_06-PC_RP_07-State variable declarations
    const [resetDetails, setResetDetails] = useState({
        username: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [resetError, setResetError] = useState({
        isnewPasswordError: false,
        confirmPasswordError: "",
        error: ""
    });

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    //PC_RP_09-PC_RP_10-Hooks for navigation
    const location = useLocation();
    const navigate = useNavigate();

    //PC_RP_11-Get value from location and set state it
    useEffect(() => {
        setResetDetails({
            ...resetDetails,
            username: location?.state?.emailId,
            oldPassword: location?.state?.password
        });
    }, []);

    //PC_RP_17-PC_RP_18-Onchange method for the input fields
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResetDetails({ ...resetDetails, [e.target.name]: e.target.value.trim() });
        validateFields(e.target.name, e.target.value.trim())
    }

    //PC_RP_32-PC_RP_43-Method to reset password
    const handleSubmit = () => {
        let isValid = validateFields()
        if (isValid) {
            showLoader()
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
                ClientId: process.env.REACT_APP_CLIENT_ID || "",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: resetDetails.username,
                Pool: userPool,
            });
            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
                Username: resetDetails.username,
                Password: resetDetails.oldPassword
            });

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                },
                onFailure: function (err) {
                    console.log(err);
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    cognitoUser.completeNewPasswordChallenge(
                        resetDetails.confirmPassword, // Use the current password as the new password
                        requiredAttributes,
                        {
                            onSuccess:async function (result) {

                                var idToken = result.getIdToken().getJwtToken();
                                var accessToken = result.getAccessToken().getJwtToken();
                                sessionStorage.setItem("accessToken", accessToken)
                                sessionStorage.setItem("idToken", idToken)
                                const decodedToken = jwtDecode(accessToken) as { [key: string]: any };
                                const userGroups = decodedToken["cognito:groups"];
                                const username = decodedToken["username"];
                                if (username) {
                                    sessionStorage.setItem("emailId", username)
        
                                }
                                await postLoginAPIs(userGroups)
                                await updateStatus()
                                hideLoader()
                                if (userGroups) {
                                    if (userGroups[0] === RoleEnum.ETM_ADMIN) {
                                        navigate("/3pl");
                                    } else {
                                        navigate("/loadMetrics");
                                    }
                                }
                                navigate("/loadMetrics");
                                // Check if the user's role matches the required role

                            },
                            onFailure: function (err) {
                                hideLoader()
                                setResetError({ ...resetError, error: err.message })
                            }
                        }
                    );
                }
            });

        }

    }

    //PC_RP_37-Method to update invitation status
    async function updateStatus() {
        try {
            let payload = {
                statusId:Status.ACCEPTED
            }
            let response = await updateInvitationStatus(payload);
            if (response?.status !== 200) {
                console.log(response)
            }
        }
        catch (error) {
            console.log(error);
        }

    }

    //PC_RP_21-PC_RP_31-Validating onchange fields
    const validateFields = (name?: string, value?: string) => {
        let isValid = true;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
        switch (name) {
            case "newPassword":
                if (value && !passwordRegex.test(value)) {
                    setResetError({ ...resetError, isnewPasswordError: true });
                    isValid = false;
                } else {
                    setResetError({ ...resetError, isnewPasswordError: false });
                    isValid = true
                }
                break;
            case "confirmPassword":
                if (value !== resetDetails.newPassword) {
                    setResetError({ ...resetError, confirmPasswordError: "The confirmation password does not match the new password" });
                    isValid = false;
                } else {
                    setResetError({ ...resetError, confirmPasswordError: "" });
                    isValid = true
                }
                break;
            default:
                if (!passwordRegex.test(resetDetails.newPassword)) {
                    setResetError({ ...resetError, isnewPasswordError: true });
                    isValid = false;
                }
                if (resetDetails.confirmPassword !== resetDetails.newPassword) {
                    setResetError({ ...resetError, confirmPasswordError: "The confirmation password does not match the new password" });
                    isValid = false;
                }
                break;
        }
        return isValid;
    }

    //PC_RP_16-Bind the reset password fields
    return (

        <div className="row justify-content-center pt-5">
            <div className="col-md-10 col-sm-12 pt-4">
                <h5 className="font-26 font-bold primary-text-color mb-2">Reset Password</h5>
                <p className="font-16 font-regular secondary-text-color mb-4">Please enter your new password here.</p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="NewPassword" className="form-label custom-label mb-1">New Password</label>
                            <div className="position-relative">
                                <input name="newPassword" type={showNewPassword ? "text" : "password"}
                                    className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                    maxLength={64} id="NewPassword" placeholder="Enter New Password" onChange={handleOnChange}
                                    onPaste={(e) => e.preventDefault()} // Prevent pasting
                                    onCopy={(e) => e.preventDefault()}
                                    onCut={(e) => e.preventDefault()}
                                    onDrag={(e) => e.preventDefault()}
                                    onDrop={(e) => e.preventDefault()} />
                                <span className={showNewPassword ? "position-absolute eye-open-icon custom-input-eye-color eye-open" : "position-absolute custom-input-eye-postion custom-input-eye-color eye-close"} onClick={() => setShowNewPassword(!showNewPassword)} onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}></span>
                            </div>
                            <div className="pt-1">
                                {resetError.isnewPasswordError && <ul className="ps-3">
                                    <li className="required-color font-12 font-regular">Minimum length should be atleast 8 characters</li>
                                    <li className="required-color font-12 font-regular">It should have lowercase letters</li>
                                    <li className="required-color font-12 font-regular">It should have uppercase letters</li>
                                    <li className="required-color font-12 font-regular">It should have symbols</li>
                                    <li className="required-color font-12 font-regular">It should contain numbers</li>
                                </ul>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="ConfirmPassword" className="form-label custom-label mb-1">Confirm Password</label>
                            <div className="position-relative">
                                <input name="confirmPassword" maxLength={64} type={showConfirmPassword ? "text" : "password"}
                                    className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2" onPaste={(e) => e.preventDefault()} // Prevent pasting
                                    onCopy={(e) => e.preventDefault()} // Prevent copying
                                    onCut={(e) => e.preventDefault()} // Prevent cutting
                                    onDrag={(e) => e.preventDefault()} // Prevent dragging
                                    onDrop={(e) => e.preventDefault()} // Prevent dropping
                                    id="ConfirmPassword" placeholder="Enter Confirm Password" onChange={handleOnChange} onKeyDown={e => { if (e.key === "Enter") handleSubmit() }} />
                                <span className={showConfirmPassword ? "position-absolute eye-open-icon custom-input-eye-color eye-open" : "position-absolute custom-input-eye-postion custom-input-eye-color eye-close"} onClick={() => setShowConfirmPassword(!showConfirmPassword)}></span>
                            </div>
                            <div className="pt-1">
                                <label className="required-color font-12 font-regular">{resetError.confirmPasswordError}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn w-100 primary-btn mt-4 mb-2" onClick={handleSubmit}>Reset Password</button>
            </div>
        </div>
    );
}