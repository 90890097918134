import { FilterObj, InsertObj, OrganizationDetails, SignupDetails, ThreePlRequest, UpdateObj } from "../interface/Interface";
import { ConnectionTestclient, client } from "./Client";
//page: number, pageSize: number, sortField: string, sortOrder: string, accessToken: string
export const getUsersDetails = async (manageUserFilter: FilterObj) => {
    let companyId: number = Number(sessionStorage.getItem("companyId") || 0);
    const response = await client(`users/v1/user?startIndex=${manageUserFilter.startIndex}&search=${manageUserFilter.search}&sortType=${manageUserFilter.sortType}&sortColumn=${manageUserFilter.sortColumn}&companyId=${companyId}&isActive=${manageUserFilter.isActive}`, { method: 'GET' });
    console.log(response);
    return response;
}
/**
 * This is used post the user details to signup API
 * @param requestBody the Logistic partner 
 * @returns the reponse from signup API
 */ 
export const postSignup= async(requestBody:SignupDetails)=>{
    // Construct the URL 
    let url = `users/v1/signup`;
    //PC_SU_26
    const response = await client(url,{method:"POST",body:requestBody})
    // Return the response data
    return response
}

export const insertUserDetail = async (userDetails: InsertObj) => {
    const response = await client(`users/v1/user`, { method: 'POST', body: userDetails })
    console.log(userDetails);
    return response;
}
 
export const updateUserDetail = async (UpdateUser: UpdateObj) => {
    const response = await client(`users/v1/user`, { method: 'PUT', body: UpdateUser })
    return response;
}
 
export const getUserDropDown = async () => {
    // Check if companyId exists in local storage
    let companyId: number = Number(sessionStorage.getItem("companyId") || 0);
    const response = await client(`users/v1/roles?companyId=${companyId}`, { method: "GET" });
    return response;
};
 
export const getCompanies = async () => {
    try {
        const response = await client(`users/v1/company?companyId=${sessionStorage.getItem("companyId")}`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};
//PC_SI_31-Method to retrieve user details
export const retrieveUserDetails = async () => {
    try {
        const response = await client(`users/v1/userdetails`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};
 
//PC_SI_43-PC_SI_44-Method to check API key validity and conguration
export const checkAPIKeyValidationandConfig = async () => {
    try {
        const response = await client(`users/v1/validateKey`, { method: "GET" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};
 
//PC_SI_48-PC_SI_49-Method to insert audit logs
export const insertLoginAudits = async () => {
    try {
        const response = await client(`users/v1/logs`, { method: "POST" });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};

export const updateInvitationStatus = async (body:any) => {
    try {
        const response = await client(`users/v1/invitationStatus`, { method: "PUT", body:body });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};
 
//PC_SI_48-PC_SI_49-Method to update invitation status
export const updateUserTheme = async (body:any) => {
    try {
        const response = await client(`users/v1/theme`, { method: "PUT",body:body });
        return response;
    }
    catch (error) {
        console.log(error);
    }
};


export const retrieveConfigurationOptions = async () => {
    try{
        const response = await client(`users/v1/configurationOption`, { method: "GET" });
        return response;
        // PS_FP_1.43-    // Set up other required details if needed
    
    }
    catch (error) {
        console.log(error);
    }
    };
    
    export const post3plForm = async (data: OrganizationDetails) => {
    //PS_FP_1.45- Set up the configuration for the POST request
    const method = data.accountInformation.companyId ? "PUT" : "POST";
    const response = await client(`users/v1/3PL`, { method: method,body:data  });
    return response;
    
    };
    
      export const retrieveOrganizationData = async (companyId: string) => {
        // PS_FP_1.46-Set up the configuration for the GET request
        try{
            const response = await client(`users/v1/3PLbyId?companyId=${companyId}`, { method: "GET" });
            return response;
            // PS_FP_1.43-// Set up other required details if needed
        }
        catch (error) {
            console.log(error);
        }
    
    
    }
    
      export const ConnectionTest = async (payload:any) => {
        // PS_FP_1.44-Set up the configuration for the test
        const config:any = {
            method: 'POST',
            url: `${payload.endpoint}`,
         // Adjusted URL
            headers: {
                'Content-Type': process.env.REACT_APP_HEADER_CONTENT_TYPE,
            }
    };
    if (payload.authenticationId === 'Basic') {
        // Set authentication as Basic
        config.headers['Authorization'] = `Basic ${btoa(`${payload.username}:${payload.password}`)}`;
    } else if (payload.authenticationId === 'OAuth') {
        // Set authentication as OAuth2.0
        config.headers['Authorization'] = `Bearer ${payload.clientId}:${payload.clientSecret}`;
    }
    
    let response:any = await ConnectionTestclient(config);
    return response;
    
      }
    
    
    
    export const get3plOrganizationDetails = async (threePLRequest: ThreePlRequest) => {
      try {
    
          // Construct the base URL 
          let url = `users/v1/3PL?startIndex=${threePLRequest?.startIndex}&searchData=${threePLRequest.searchData}&sortType=${threePLRequest.sortType}&sortColumn=${threePLRequest.sortColumn}&statusId=${threePLRequest.statusId}&connectionStatusId=${threePLRequest.connectionStatusId}&fromDate=${threePLRequest.fromDate}&toDate=${threePLRequest.toDate}`;

          // Make the HTTP request
          const response = await client(url,{method:"GET"})
    
          // Return the response data
          return response
      } catch (error) {
    
          console.error('Error fetching grid data:', error);
          return null; 
      }
    };
    /**
     * The retreives the Status from the given endpoint
     * @returns 
     */
    export const get3plStatus = async () => {
      try {
    
          // Construct the base URL 
          let url = `users/v1/3plstatus`;
    
    
          // Make the HTTP request
          const response = await client(url,{method:"GET"})
    
          // Return the response data
          return response
      } catch (error) {
          // Handle any errors that occur during the process
          console.error('Error fetching grid data:', error);
      }
    };
