import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'; // Assuming you'll use this later
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ChildComponentProps, verificationDetail, verificationErr } from '../interface/Interface';

export const Verifications: React.FC<ChildComponentProps> = ({ showLoader, hideLoader }) => {

    //PC_VC_07-PC_SI_10 - Declaration and initialization of state variables 
    const [verificationDetail, setVerificationCode] = useState<verificationDetail>({
        emailId: "",
        verificationCode: 0,
        newPassword: "",
        confirmPassword: ""
    });
    const [verificationErr, setVerificationErr] = useState<verificationErr>({
        verificationCodeErr: "",
        confirmPasswordErr: "",
        isnewPasswordError: false,
        error: "",
    });

    const [timer, setTimer] = useState(300);
    const [isTimerRunning, setIsTimerRunning] = useState(true);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    //PC_VC_11-Hook used to navigate
    const location = useLocation();
    const navigate = useNavigate();

    //PC_VC_12-PC_VC_18-Timer function
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setIsTimerRunning(false)
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);

    useEffect(() => {
        setVerificationCode({
            ...verificationDetail,
            emailId: location?.state?.emailId,
        });

    }, []);

     //PC_VC_40-Function to format seconds to 'MM:SS' format
    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    //PC_SI_16-PC_SI_17 - Handle onchange values of fields
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVerificationCode({ ...verificationDetail, [e.target.name]: e.target.value.trim() });
        if (e.target.name != "verificationCode") {
            validateFields(e.target.name, e.target.value)
        } else {
            setVerificationErr({ ...verificationErr, verificationCodeErr: "" })
        }
    }

    //PC_VC_21-PC_VC_28-Validating fields values
    const validateFields = (name?: string, value?: string) => {
        let isValid = true;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
        switch (name) {
            case "newPassword":
                if (value && !passwordRegex.test(value)) {
                    setVerificationErr({ ...verificationErr, isnewPasswordError: true });
                    isValid = false;
                } else {
                    setVerificationErr({ ...verificationErr, isnewPasswordError: false });
                    isValid = true
                }
                break;
            case "confirmPassword":
                if (value !== verificationDetail.newPassword) {
                    setVerificationErr({ ...verificationErr, confirmPasswordErr: "The confirmation password does not match the new password" });
                    isValid = false;
                } else {
                    setVerificationErr({ ...verificationErr, confirmPasswordErr: "" });
                    isValid = true
                }
                break;
            case "verificationCode":
                if (!verificationDetail.verificationCode || !(verificationDetail.verificationCode > 5)) {
                    setVerificationErr({ ...verificationErr, verificationCodeErr: "Code must be at least 6 characters" });
                    isValid = false;
                } else {
                    setVerificationErr({ ...verificationErr, confirmPasswordErr: "" });
                    isValid = true
                }
                break;
            default:
                if (!passwordRegex.test(verificationDetail.newPassword)) {
                    setVerificationErr(prevState => ({
                        ...prevState,
                        isnewPasswordError: true,
                    }));
                    isValid = false;
                }
                if (verificationDetail.confirmPassword !== verificationDetail.newPassword) {
                    setVerificationErr(prevState => ({
                        ...prevState,
                        confirmPasswordErr: "The confirmation password does not match the new password",
                    }));
                    isValid = false;
                }
                if (!verificationDetail.verificationCode || !(verificationDetail.verificationCode > 5)) {
                    setVerificationErr(prevState => ({
                        ...prevState,
                        verificationCodeErr: "Code must be at least 6 characters",
                    }));
                    isValid = false;
                }
                break;
        }
        return isValid;
    }

    //PC_VC_29-PC_VC_37-Reset Password
    const handleSubmit = () => {
        let isValid = validateFields()
        if (isValid) {
            showLoader()
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
                ClientId: process.env.REACT_APP_CLIENT_ID || "",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: verificationDetail.emailId,
                Pool: userPool,
            });

            let code = verificationDetail.verificationCode.toString()
            cognitoUser.confirmPassword(code, verificationDetail.confirmPassword, {
                onSuccess: function (result) {
                    hideLoader()
                    navigate("/resetSuccessful")
                    console.log(result);
                },
                onFailure: function (err) {
                    hideLoader()
                    setVerificationErr({ ...verificationErr, error: err.message })
                }
            });
        }
    }

      //PC_VC_39-Resend confirmation code
    const resendConfirmationCode = () => {
        if(!isTimerRunning){
            showLoader()
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
                ClientId: process.env.REACT_APP_CLIENT_ID || "",
            };
    
            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: verificationDetail.emailId,
                Pool: userPool,
            });
    
            cognitoUser.forgotPassword({
                onSuccess: function (result) {
                    hideLoader()
                    setTimer(300);
                    setIsTimerRunning(true);
                },
                onFailure: function (err) {
                    hideLoader()
                    // Handle failure
                    setVerificationErr({ ...verificationErr, error: err.message })
                }
            });
        }
    }
    return (

        <div className="row justify-content-center pt-5">
            <div className="row justify-content-center pt-5">
                <div className="col-md-10 col-sm-12 pt-4">
                    <h5 className="font-26 font-bold primary-text-color mb-2">Verification</h5>
                    <p className="font-16 font-regular secondary-text-color mb-4">Please enter the code that has been sent to your business email
                        address.</p>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="VerificationCode" className="form-label custom-label mb-1">Verification Code</label>
                                <input type="text" name="verificationCode" className="form-control custom-form-input" id="VerificationCode" placeholder="Enter Verification Code" value={verificationDetail.verificationCode != 0 ? verificationDetail.verificationCode : ""} onChange={handleOnChange}  onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}/>
                                <div className="mb-3 pt-3 d-flex justify-content-between align-items-center">
                                    <span className="d-flex align-items-center" >
                                        <span className={isTimerRunning ? "timer-icon me-2" : "timer-icon me-2 d-none"}></span>
                                        <label className={isTimerRunning ? "font-12 font-medium primary-text-color" : "font-12 font-medium primary-text-color d-none"}>{formatTime(timer)} mins</label>
                                    </span>
                                    <a href="#" className={isTimerRunning ? "text-decoration-none link-color font-14 text-end font-medium disabled" : "text-decoration-none link-color font-14 text-end font-medium"} onClick={resendConfirmationCode}>Resend Code</a>
                                </div>
                                {verificationErr.verificationCodeErr && <label className="required-color font-12 font-regular">{verificationErr.verificationCodeErr}</label>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="NewPassword" className="form-label custom-label mb-1">New Password</label>
                                <div className="position-relative">
                                    <input  maxLength={64} type={showNewPassword ? "text" : "password"} name="newPassword" className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2" id="NewPassword" placeholder="Enter New Password" value={verificationDetail.newPassword} onChange={handleOnChange} onPaste={(e) => e.preventDefault()} // Prevent pasting
                                    onCopy={(e) => e.preventDefault()}
                                    onCut={(e) => e.preventDefault()}
                                    onDrag={(e) => e.preventDefault()}
                                    onDrop={(e) => e.preventDefault()} 
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}
                                    />
                                    <span className={showNewPassword ? "position-absolute eye-open-icon custom-input-eye-color eye-open" : "position-absolute custom-input-eye-postion custom-input-eye-color eye-close"} onClick={() => setShowNewPassword(!showNewPassword)}></span>
                                </div>
                                <div className="pt-1">
                                    {verificationErr.isnewPasswordError && <ul className="ps-3">
                                        <li className="required-color font-12 font-regular">Minimum length should be atleast 8 characters</li>
                                        <li className="required-color font-12 font-regular">It should have lowercase letters</li>
                                        <li className="required-color font-12 font-regular">It should have uppercase letters</li>
                                        <li className="required-color font-12 font-regular">It should have symbols</li>
                                        <li className="required-color font-12 font-regular">It should contain numbers</li>
                                    </ul>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="ConfirmPassword" className="form-label custom-label mb-1">Confirm Password</label>
                                <div className="position-relative">
                                    <input maxLength={64} type={showConfirmPassword ? "text" : "password"} name="confirmPassword" className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2" id="NewPassword" placeholder="Enter Confirm Password" value={verificationDetail.confirmPassword} onChange={handleOnChange} onPaste={(e) => e.preventDefault()} // Prevent pasting
                                    onCopy={(e) => e.preventDefault()}
                                    onCut={(e) => e.preventDefault()}
                                    onDrag={(e) => e.preventDefault()}
                                    onDrop={(e) => e.preventDefault()}
                                    onKeyDown={e => { if (e.key === "Enter") handleSubmit() }}  />
                                    <span className="position-absolute eye-open-icon custom-input-eye-color eye-close" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></span>
                                </div>
                                <div className="pt-1">
                                    {verificationErr.confirmPasswordErr && <label className="required-color font-12 font-regular">{verificationErr.confirmPasswordErr}</label>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-1">
                        <label className="required-color font-12 font-regular">{verificationErr.error}</label>
                    </div>
                    <button type="button" className="btn w-100 primary-btn mt-4 mb-2" onClick={handleSubmit}>Reset Password</button>
                    <button type="button" className="btn w-100 secondary-btn mt-2 mb-2" onClick={() => navigate("/")}>Back to Sign In</button>
                </div>
            </div>
        </div>
    );
}
