//Enum to store roles
export enum Theme {
  DARK = "DARK",
  LIGHT = "LIGHT"
}

export enum RoleEnum {
  ETM_ADMIN = "ETM-Admin",
  ETM_STAFF = "ETM-Staff",
  THIRD_PARTY_ADMIN = "Third-Party-Admin",
  THIRD_PARTY_STAFF = "Third-Party-Logistics-Staff",
}

export enum Status {
  ACCEPTED =  2,
  INVITED =1
}