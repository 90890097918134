import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { jwtDecode } from "jwt-decode";
import { RoleEnum, Theme } from '../enums/enums';
import { useNavigate, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { getCompanies, updateUserTheme } from '../service/Api';
import { UpdateTheme, company } from '../interface/Interface';
import AlertComponent from './ResuableComponents/AlertComponent';
//PS_22
import { useTheme } from './ThemeContext';

export const HeaderComponent = () => {

  const [company, setCompany] = useState<company[]>([]);
  //PC_RP_09-PC_RP_10-Hooks for navigation
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("accessToken") || "";
  let decodedToken = jwtDecode(accessToken) as { [key: string]: any };
  let groups = decodedToken["cognito:groups"];

  const { themeType, changeTheme } = useTheme();

  //PC-43 switch theme method is invoked on click of the sun or moon image.
  const switchTheme = (theme: Theme) => {
    const newTheme = theme;
    if (changeTheme) {
      changeTheme(newTheme);
    }
  }

  useEffect(() => {
    //SQ_5
    switchTheme(localStorage.getItem("theme") == "Dark" ? Theme.DARK : Theme.LIGHT)
    if (sessionStorage.getItem("isImpersonate") == "true" || groups[0] == RoleEnum.ETM_STAFF) {
      //SQ_H_1.7
      getCompany()
    }
  }, [sessionStorage.getItem("isImpersonate") == "true"])
  //SQ_H_1.7 - 1.15
  const getCompany = async () => {
    try {
      let response = await getCompanies()
      console.log(response.data.data);
      if (response?.data?.statusCode == 200) {
        console.log(response?.data?.data);
        setCompany(response?.data?.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleLogout = () => {
    try {
      let accessToken = sessionStorage.getItem("accessToken")
      if (accessToken) {

        const decodedToken = jwtDecode(accessToken) as { [key: string]: any };
        const userGroups = decodedToken["cognito:groups"];

        let emailID = sessionStorage.getItem("emailId")
        if (emailID) {
          const poolData = {
            UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
            ClientId: process.env.REACT_APP_CLIENT_ID || "",
          };
          const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
          const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: emailID,
            Pool: userPool,
          });

          cognitoUser.signOut();
          sessionStorage.clear()
          if (userGroups) {
            if (userGroups[0] === RoleEnum.ETM_ADMIN) {
              navigate("/admin");
            } else {
              navigate("/");
            }
          } else {
            navigate("/");
          }
          sessionStorage.clear()
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function updateTheme(body: UpdateTheme) {
    try {
      let response = await updateUserTheme(body);
      if (response?.data?.statusCode == 200) {
        localStorage.setItem("themeId", `${body.themeId}`)
        localStorage.setItem("theme", `${body.themeId === 2 ? "Light" : "Dark"}`);
      }
      console.log(response);
    } catch (error) {
      console.log(error)
    }
  }
  //PS_14
  return (
    <>
      <div>
        {
          <nav className="navbar navbar-expand-md body py-2 shadow-sm">
            <div className="container-fluid" >
              <a className="navbar-brand ms-2 d-flex text-decoration-none align-items-center primary-text-color"
                href={groups[0] === RoleEnum.ETM_ADMIN ? "/3pldetails" : "/loadmetrics"}> <span className="Nav-logo logo"></span><label className="font-16 ms-2 font-bold">ETM</label></a>
              <div className="d-flex align-items-center">
                <div className="d-md-none d-block">
                  <label htmlFor="switch" className="darktheme-switch primary-btn me-3">
                    <input type="checkbox" className="d-none" id="switch" checked={localStorage.getItem("theme") == "Dark"} />
                    <div className="icon icon--sun">
                      <img className="theme-outline p-1" onClick={async () => { await updateTheme({ themeId: 2 }); switchTheme(Theme.LIGHT) }} src="images/sun-icon.svg" alt="sun-icon" />
                    </div>
                    <div className="icon icon--moon">
                      <img className="theme-outline p-1" onClick={async () => { await updateTheme({ themeId: 1 }); switchTheme(Theme.DARK) }} src="images/moon-icon.svg" alt="moon-icon" />
                    </div>
                  </label>
                </div>
                <button className="navbar-toggler hamburger-color collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon">
                    <img src="images/hamburger-icon.svg" alt="hamburger-icon" />
                  </span>
                </button>
              </div>

              <div className="navbar-collapse collapse" id="navbarsExample04" >
                <ul className="navbar-nav me-auto mb-2 mb-md-0 gap-md-4 ms-md-4">
                  {groups[0] === RoleEnum.ETM_ADMIN ?
                    <li className="nav-item">
                      <a className={window.location.pathname === "/3pldetails" ||window.location.pathname === "/3plOnboarding" ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/3pldetails">3PL</a>
                    </li> : <></>}

                  {groups[0] === RoleEnum.THIRD_PARTY_STAFF || groups[0] === RoleEnum.ETM_STAFF || groups[0] === RoleEnum.THIRD_PARTY_ADMIN || sessionStorage.getItem("isImpersonate") == "true" ?
                    <li className="nav-item">
                      <a className={window.location.pathname === "/loadmetrics" ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/loadmetrics">Loads</a>
                    </li> : <></>}

                  {groups[0] === RoleEnum.THIRD_PARTY_STAFF|| groups[0] === RoleEnum.THIRD_PARTY_ADMIN || sessionStorage.getItem("isImpersonate") == "true" ?
                    <li className="nav-item">
                      <a className={window.location.pathname === "/carriers" ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/carriers">Carriers</a>
                    </li> : <></>}

                  <li className="nav-item">
                    <a className={window.location.pathname === "/reports" ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/reports">Reports</a>
                  </li>

                  {groups[0] === RoleEnum.ETM_ADMIN || groups[0] === RoleEnum.THIRD_PARTY_ADMIN ?
                    <li className="nav-item">
                      <a className={window.location.pathname === "/manageusers" ? "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0 active" : "nav-link font-14 top-nav-link py-3 py-md-1 ps-3 pe-0 me-2 ps-md-0"} href="/manageusers">Manage Users</a>
                    </li> : <></>}
                </ul>
                <ul className="list-unstyled mb-0 d-block d-md-flex align-items-center">
                  <li className="d-md-block d-none">
                    <label htmlFor="switch-toggle" className="darktheme-switch primary-btn me-3">
                      <input type="checkbox" className="d-none" id="switch-toggle" checked={localStorage.getItem("theme") == "Dark"} />
                      <div className="icon icon--sun">
                        <img className="theme-outline p-1" src="images/sun-icon.svg" alt="sun-icon" onClick={async () => { await updateTheme({ themeId: 2 }); switchTheme(Theme.LIGHT) }} />
                      </div>
                      <div className="icon icon--moon">
                        <img className="theme-outline p-1" src="images/moon-icon.svg" alt="moon-icon" onClick={async () => { await updateTheme({ themeId: 1 }); switchTheme(Theme.DARK) }} />
                      </div>
                    </label>
                  </li>
                  {(sessionStorage.getItem("isImpersonate") == "true" || groups[0] == RoleEnum.ETM_STAFF) ?
                    <li>
                      <div className="form-floating">
                        <select className="form-select custom-form-input select-filter custom-select-arrow font-12" id="floatingSelect" aria-label="Floating label select example">
                          {company.map((companyItem, index) => (
                            <option key={index} value={companyItem.companyId}>
                              {companyItem.companyName}
                            </option>
                          ))}
                        </select>
                        <label className="font-11 top-nav-label-color pt-3 pb-0 font-regular text-nowrp" htmlFor="floatingSelect">Company Name</label>
                      </div>
                    </li> : <></>}
                  <li>
                    <div className="btn-group ms-0 ms-md-3 ">
                      <button type="button" className="d-flex align-items-center border-0 bg-transparent shadow-none py-2 primary-text-color font-14 font-medium dd-arrow-rotate" data-bs-toggle="dropdown" aria-expanded="false">
                        {sessionStorage.getItem("username")}
                        <img src="images/profile-arrow.svg" alt="accordion-arrow" className="dd-arrow ms-2" data-bs-toggle="collapse" data-bs-target="#ReportAcc1" aria-expanded="false" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-md-end primary-bg-color p-3 shadow-sm">
                        <li className="text-center">
                          <p className="primary-text-color mb-1 font-14 font-medium">{sessionStorage.getItem("username")}</p>
                          <p className="font-12 font-regular mb-0 top-nav-label-color m-0 mb-2">{sessionStorage.getItem("emailId")}</p>
                        </li>
                        <li className="nav-border">
                          <button className="btn dropdown-item text-center my-2 link-color link-btn" onClick={() => { navigate('/profile') }} type="button">Profile</button>
                          <button className="btn dropdown-item text-center my-2 link-color link-btn" type="button">API Documentation</button>
                        </li>
                        <li><button className="btn dropdown-item text-center mt-2 link-btn logout-btn" type="button" onClick={handleLogout}>
                          <img src="images/logout-icon.svg" className="logout-icon-color me-2" />
                          Logout</button></li>
                      </ul>
                    </div>
                    {
                      //PS_39
                      (sessionStorage.getItem("isAPIKeyValid") == "false") ?
                        <div>
                          <AlertComponent errHeading={'Alert'}
                            err={`Your software license has been expired, so please contact
          the administrator to renew your license for to continue
          using the application without interruption.`}
                            onClose={handleLogout}></AlertComponent>
                        </div> : <></>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        }
      </div>
    </>
  );
}