//PC_SU_FE_1 ,2 
import all from 'country-calling-code';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { ChildComponentProps, SignupDetails } from "../interface/Interface";
import { postSignup } from '../service/Api';
import AlertComponent from './ResuableComponents/AlertComponent';
import { SomethingWentWrong } from './ResuableComponents/SomethingWentWrong';

//PC_SU_FE_6
export const SignupComponent: React.FC<ChildComponentProps> = ({ showLoader, hideLoader }) => {
//PC_SU_FE_7
const navigate=useNavigate()
    const [signupDetails, setSignupDetails] = useState({
        userName: "",
        businessEmailAddress: "",
        companyName: "",
        phoneNumberExt: "+1",
        phoneNumber: "",
        password: "",
    })
    //PC_SU_FE_8
    const [signupError, setSignupError] = useState({
        errUsername: "",
        errEmailId: "",
        errCompanyName: "",
        errPhoneNumber: "",
        errPassword: false,
        err: ""
    });
    //PC_SU_FE_9
    const [showPassword, setShowPassword] = useState(false)
    const [isSomethingWentWrong, setisSomethingWentWrong] = useState(false);
    const [isSentForApproval, setSentForApproval] = useState<boolean>(false);
    
    /**
     * This function is used to validate the fields based on the given parameter
     * PC_SU_FE_17
     * @param name This name of the field that needs to be validated 
     * @param value This is value of the field which needs to ne validated
     * @returns True/false based on the validation condition
     */
    const validateFields = (name: any, value: string) => {
        switch (name) {
            case 'userName':
                if (!value) {
                    setSignupError(value=>({ ...value, errUsername: 'Please enter username' }));
                    return false;
                }
                else {
                    setSignupError(value=>({ ...value, errUsername: '' }))
                    return true
                }
                
            case 'businessEmailAddress':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!value || !emailRegex.test(value)) {
                    setSignupError(value=>({ ...value, errEmailId: 'Please enter valid email address' }));
                    return false;
                } else {
                    setSignupError(value=>({
                        ...value,
                        errEmailId: ''
                    }))
                    return true
                }
            case 'password':
                const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
                if (!value || !passwordRegex.test(value)) {
                    setSignupError(value=>({...value,errPassword:true}))
                    return false;
                } else {
                    
                    setSignupError(value=>({
                        ...value,
                        errPassword: false
                    }))
                    return true
                }
            case 'companyName':
                if (!value) {
        
                    setSignupError(value=>({ ...value, errCompanyName: 'Please enter company name' }));
                    return false;
                } else {
                    setSignupError(value=>({
                        ...value,
                        errCompanyName: ''
                    }))
                    return true
                }
            case 'phoneNumber':
                if (!value || value.length < 8) {
                    setSignupError(value=>({ ...value, errPhoneNumber: 'Please enter valid phone number' }));
                    return false;
                } else {
                    setSignupError(value=>({
                        ...value,
                        errPhoneNumber: ''
                    }))
                    return true
                }
            default:
                return true
        }
    };

/**
 * This Function will validate all the fields in form
 * PC_SU_FE_19
 * @returns boolean based on  validation of all fields
 */
    const validateForm = () => {
        let isValid = false;
        for (const fieldName in signupDetails) {
            if (Object.prototype.hasOwnProperty.call(signupDetails, fieldName)) {
                const key = fieldName as keyof SignupDetails;
                isValid = validateFields(key, signupDetails[key]) || false;
            }
        }
        return isValid;
    };
    //PC_SU_FE_18
    async function signup() {
        //PC_SU_FE_19
        try {
            if (validateForm() && signupError.errCompanyName === '' && !signupError.errPassword && signupError.errPhoneNumber === '' && signupError.errUsername === '' && signupError.errUsername === '') {
                //PC_SU_23
                const encryptedPassword = btoa(signupDetails.password);
                const requestBody = {
                    ...signupDetails,
                    password: encryptedPassword,
                };
                showLoader()
                // Make an API call with the signup details and the base64 encoded password
                //PC_SU_24
                const response = await postSignup(requestBody)
                // Assign the response
                if (response.data.statusCode !== 200) {
                    //PC_SU_27
                    if (response.data.statusCode == 400 && response.data.statusMessage == "Company name already exists") {
                        setSignupError({ ...signupError, errCompanyName: response.data.statusMessage });
                        //PC_SU_28
                    } else if (response.data.statusCode == 400 && response.data.statusMessage == "Email Address already exists") {
                        setSignupError({ ...signupError, errEmailId: response.data.statusMessage });
                        //PC_SU_29
                    } else if (response.data.statusCode == 400 && response.data.statusMessage.includes("User account already exists and is expired")){
                        setSignupError({ ...signupError, errEmailId: "User account already exists and expired. Please contact admin" });
                    } else {
                        setisSomethingWentWrong(true)
                    }
                    //PC_SU_30
                } else if (response.data.statusCode === 200) {
                    setSentForApproval(true)
                }
            }
        } catch (e: any) {
            //PC_SU_31
            setisSomethingWentWrong(true)
            console.log(e)
        } finally {
            hideLoader()
        }
    }
/*PC_SU_FE_15*/
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignupDetails({ ...signupDetails, [e.target.name]: e.target.value.trim() });
/*PC_SU_FE_16*/
        if (e.target.name === 'password') {
            validateFields(e.target.name, e.target.value);
        }
    }

    return (
        <>
         {isSentForApproval? <AlertComponent errHeading="Alert" onClose={()=>{ navigate('/admin')}} err ="Thank you for signing up. Your account is pending admin approval. You will be able to login once approved." />:<></>}
         {isSomethingWentWrong ? <SomethingWentWrong onClose={() => setisSomethingWentWrong(false)} /> : <></>}
 
            <div className="row justify-content-center pt-1">
                <div className="col-md-10 col-sm-12 pt-4">
                    <h5 className="font-26 font-bold primary-text-color mb-2">Sign Up</h5>
                    <p className="font-16 font-regular secondary-text-color mb-4">Please enter your details to sign up</p>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="Username" className="form-label custom-label mb-1">
                                    Username
                                </label>
                                <input type="text"
                                    className="form-control custom-form-input"
                                    id="Username"
                                    placeholder="Enter Username"
                                    name='userName'
                                    maxLength={64}
                                    onChange={handleOnChange}
                                    onKeyDown={e => { if (e.key === "Enter") signup() }}
                                    value={signupDetails.userName}
                                />
                                {signupError.errUsername !== "" ? <><div className="pt-0">
                                    <div className="pt-0">
                                        <label className="required-color font-12 font-regular">{signupError.errUsername}</label>
                                    </div>
                                </div></> : <></>}
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="EmailAddress" className="form-label custom-label mb-1">Business Email Address</label>
                                    <input type="text"
                                        name='businessEmailAddress'
                                        maxLength={64}
                                        className="form-control custom-form-input"
                                        id="BusinessEmailAddress"
                                        placeholder="Enter Business Email Address"
                                        onChange={handleOnChange}
                                        onKeyDown={e => { if (e.key === "Enter") signup() }}
                                        value={signupDetails.businessEmailAddress}
                                    />
                                    {signupError.errEmailId !== "" ? <><div className="pt-0">
                                        <label className="required-color font-12 font-regular">{signupError.errEmailId}</label>
                                    </div></> : <></>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="CompanyName" className="form-label custom-label mb-1">Company Name</label>
                                    <input type="text" className="form-control custom-form-input pb-1" id="CompanyName"
                                        placeholder="Enter Company Name"
                                        onChange={handleOnChange}
                                        onKeyDown={e => { if (e.key === "Enter") signup() }}
                                        name='companyName'
                                        maxLength={64}
                                        value={signupDetails.companyName}
                                    />
                                    {signupError.errCompanyName !== "" ? <> <div className="pt-0">
                                        <label className="required-color font-12 font-regular">{signupError.errCompanyName}</label>
                                    </div></>:<></>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="PhoneNumber" className="form-label custom-label mb-1">Phone Number</label>
                                    <div className="input-group mb-1">
                                        <select
                                            onChange={(e) => { setSignupDetails(prevState => ({ ...prevState, phoneNumberExt: (e.target.value) })) }}
                                            className="form-select pe-3 custom-form-input custom-select-number"
                                            aria-label="Default select example"
                                            value={signupDetails.phoneNumberExt}
                                        >
                                           {all.filter(country => country.country === 'United States').map((country, index) => (
                                                <option key={index} value={country.countryCodes}>
                                                    {country.countryCodes}
                                                </option>
                                            ))}
                                        </select>
                                        <input type="text" className="form-control custom-form-input"
                                            id="PhoneNumber"
                                            name='phoneNumber'
                                            value={signupDetails.phoneNumber}
                                            onKeyDown={e => { if (e.key === "Enter") signup() }}
                                            onChange={handleOnChange}
                                            maxLength={15}
                                            placeholder="Enter Phone Number" />
                                    </div>
                                    {signupError.errPhoneNumber !== "" ? <> <div className="pt-0">
                                        <label className="required-color font-12 font-regular">{signupError.errPhoneNumber}</label>
                                    </div></> : <></>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label custom-label mb-1">Password</label>
                                    <div className="position-relative">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control custom-form-input password-eye-hide custom-form font-14 font-regular py-2"
                                            id="password"
                                            name='password'
                                            maxLength={64}
                                            placeholder="Enter Password"
                                            value={signupDetails.password}
                                            onKeyDown={e => { if (e.key === "Enter") signup() }}
                                            onChange={handleOnChange}
                                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                                            onCopy={(e) => e.preventDefault()}
                                            onCut={(e) => e.preventDefault()}
                                            onDrag={(e) => e.preventDefault()}
                                            onDrop={(e) => e.preventDefault()}
                                        />
                                        <span className={showPassword ? "position-absolute eye-open-icon custom-input-eye-color eye-open" : "position-absolute custom-input-eye-postion custom-input-eye-color eye-close"}
                                            onClick={() => setShowPassword(!showPassword)}
                                        ></span>
                                    </div>
                                    {signupError.errPassword &&<ul className="ps-3">
                                    <li className="required-color font-12 font-regular">Minimum length should be atleast 8 characters</li>
                                    <li className="required-color font-12 font-regular">It should have lowercase letters</li>
                                    <li className="required-color font-12 font-regular">It should have uppercase letters</li>
                                    <li className="required-color font-12 font-regular">It should have symbols</li>
                                    <li className="required-color font-12 font-regular">It should contain numbers</li>
                                </ul>}
                                </div>
                            </div>
                        </div>
                        <button type="button"
                            className="btn w-100 primary-btn mt-1 mb-2"
                            onClick={() => signup()}
                        >Sign Up</button>
                        <p className="text-center font-14 font-regular primary-text-color mt-3 mb-lg-0 mb-4">Already have an account? <a href='' className="text-decoration-none link-color font-14 font-medium"onClick={()=>navigate("/admin")}>Sign In</a></p>
                    </div>
                </div>
            </div></>
    );
}