import _ from "lodash"
import { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { RoleEnum } from "../enums/enums"
import { FilterObj, InsertErrorObj, InsertObj, ManageUser, Role, UpdateObj } from "../interface/Interface"
import { getUserDropDown, getUsersDetails, insertUserDetail, updateUserDetail } from "../service/Api"
import { isValidEmail } from "../utils/utils"
import Loader from "./ResuableComponents/Loader"
import NoDataFound from "./ResuableComponents/NoDataFound"
import NoResultsFound from "./ResuableComponents/NoResultsFound"
import { SuccessToast } from "./ResuableComponents/SuccessToast"


function ManageUsers() {

    const [refresh, setRefresh] = useState(0);
    //PS_1.3
    let filterObj: FilterObj = {
        search: "",
        isActive: "",
        startIndex: 0,
        sortColumn: "",
        sortType: "desc"
    }

    //PS_2.2
    let insertObj: InsertObj = {
        name: "",
        emailId: "",
        roleId: 0,
        roleName: "",
        companyId: Number(sessionStorage.getItem("companyId") || 0),
    }

    let insertErrorObj: InsertErrorObj = {
        name: "",
        emailId: "",
        role: ""
    }

    let UpdateObj: UpdateObj = {
        userId: "",
        userName: "",
        roleId: 0,
        roleName: "",
        currentRoleName: "",
        isActive: null
    }

    //PS_1.4
    const [manageUserFilter, setManageUserFilter] = useState(filterObj);
    //PS_1.5
    const [userDetails, setUserDetails] = useState<ManageUser[]>([]);
    //PS_1.6
    const [loader, setLoader] = useState(false);
    //PS_1.7
    const [errorPopup, setErrorPopup] = useState(false)
    //PS_1.8
    const [totalCount, setTotalCount] = useState(0);
    //PS_2.3
    const [insertUser, setInsertUser] = useState<InsertObj>(insertObj)
    const [insertError, setInsertError] = useState<InsertErrorObj>(insertErrorObj)

    const [updateUser, setUpdateUser] = useState<UpdateObj>(UpdateObj)
    const [update, setUpdate] = useState<boolean[]>([]);
    const [userDropDown, setUserDropdown] = useState<Role[]>([])
    const [updateError, setUpdateError] = useState("");
    const [ToastMessage, setToastMessage] = useState("")
    const [noDataFound, setNoDataFound] = useState(false)
    const [noResultsFound, setNoResultsFound] = useState(false)

    useEffect(() => {
        if (ToastMessage != "") {
            const timer = setTimeout(() => {
                setToastMessage(""); // Hide the toast message after 10 seconds
            }, 3000);

            return () => clearTimeout(timer); // Clear the timer on component unmount or when openToastMessage changes
        }
    }, [ToastMessage]);

    //PS_1.9
    useEffect(() => {
        getDropDown()
        getUsers()
    }, [manageUserFilter.sortColumn, manageUserFilter.sortType, manageUserFilter.startIndex, refresh])

    useEffect(() => {
        if (updateUser.isActive !== null) {
            updateUserDetails();
        }
    }, [updateUser.isActive]);
    //PS_1.12 - 1.37
    async function getUsers() {
        try {
            setLoader(true)
            let result = await getUsersDetails(manageUserFilter)
            if (result?.data?.statusCode == 200) {
                setUserDetails(result?.data?.data)
                setTotalCount(result?.data?.totalCount)
                setNoDataFound(false);
                setNoResultsFound(false);
            } else if (result?.data?.statusCode == 404) {
                if (_.isEqual(manageUserFilter, filterObj)) {
                    setNoDataFound(true)
                } else {
                    setNoResultsFound(true)
                }
                setUserDetails([])
                setTotalCount(0)
            } else {
                setNoDataFound(true)
                setUserDetails([])
                setTotalCount(0)
                setErrorPopup(true)
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoader(false);
        }
    }
    //PS_2.21 - 2.37
    async function insertUserDetails() {
        try {
            //PS_2.22
            setLoader(true);
            //PS_2.23
            let result = await insertUserDetail(insertUser)
            if (result?.data?.statusCode == 200) {
                getUsers()
                setInsertUser(insertObj);
                setToastMessage(`User Inserted Successfully`)
            } else {
                let error: string = ((result?.data?.statusMessage))
                setErrorPopup(true)
                if (error.includes("UsernameExistsException")) {
                    setInsertError(prevstate => ({
                        ...prevstate,
                        emailId: "Email address already exists"
                    }));
                }
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoader(false)
        }
    }

    async function getDropDown() {
        try {
            setLoader(true);
            let response = await getUserDropDown()
            setUserDropdown(response?.data?.data)
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoader(false);
        }
    }

    function buildDropDown() {
        try {
            return userDropDown.map((role, index) => {
                return (
                    <option tabIndex={index} className={role.Role} value={role.ID}>
                        {role.Role == RoleEnum.ETM_ADMIN || role.Role == RoleEnum.THIRD_PARTY_ADMIN ? "Admin" : "Staff"}
                    </option>)
            })
        } catch (error) {
            console.log(error)
        }
    }
    function buildUsers() {
        try {

            return userDetails.map((user, index) => (
                <tr key={index}>
                    <td className="text-nowrap">{user?.username}</td>
                    <td className="text-nowrap">{user?.emailId}</td>
                    <td className="text-nowrap">
                        {update[index] === true ? (
                            <>
                                <select
                                    className="form-select custom-form-input custom-select-arrow primary-border-color form-drop"
                                    value={updateUser?.roleId}
                                    onChange={(e) => {
                                        setUpdateUser(prevstate => ({
                                            ...prevstate,
                                            roleId: Number(e.target.value),
                                            roleName: e.target.options[e.target.selectedIndex].className,
                                            userId: user.userId,
                                            userName: user.emailId,
                                            currentRoleName: user.roleName
                                        }));
                                    }}
                                >
                                    <option value={0} selected>Select</option>
                                    {buildDropDown()}
                                </select>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{updateError}</label>
                                </div>
                            </>
                        ) : (
                            <>
                                <span>{user?.roleName == RoleEnum.ETM_ADMIN || user?.roleName == RoleEnum.THIRD_PARTY_ADMIN ? "Admin" : "Staff"}</span>
                            </>
                        )}
                    </td>
                    <td className="text-nowrap">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input custom-switch-input custom-toggle"
                                type="checkbox"
                                id={`flexSwitchCheckDefault${index}`}
                                checked={user?.isActive}
                                disabled={user.isOwner}
                                onChange={(e) => {
                                    //PS_17.0-17.4
                                    setUpdateUser(prevstate => ({
                                        ...prevstate,
                                        userId: user.userId,
                                        userName: user.emailId,
                                        currentRoleName: user.roleName,
                                        isActive: e.target.checked
                                    }));
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`flexSwitchCheckDefault${index}`}                        >
                                {user.isActive ? 'Yes' : 'No'}
                            </label>
                        </div>
                    </td>
                    <td className="text-nowrap">{user?.statusValue}</td>
                    <td className="text-center">
                        {update[index] === true ? (
                            <div>
                                <button
                                    type="button"
                                    className="border-0 px-3 bg-transparent"
                                    onClick={() => {//PS_19.0
                                        updateValidation()
                                    }}
                                >
                                    <img src="images/tick-icon 1.svg" alt="Tick" title="Tick" />
                                </button>
                                <button
                                    type="button"
                                    className="border-0 px-3 bg-transparent"
                                    onClick={() => { setUpdateUser(UpdateObj); setUpdate([]); setUpdateError("") }}
                                >
                                    <img src="images/close-icon 1.svg" alt="Cancel" title="Cancel" />
                                </button>
                            </div>
                        ) : (
                            <button disabled={user.isOwner}
                                type="button"
                                className="border-0 px-3 bg-transparent"
                                onClick={() => {
                                    let updateView: boolean[] = [];
                                    updateView[index] = true
                                    setUpdate(updateView)
                                    setUpdateUser(prevstate => ({
                                        ...prevstate,
                                        userId: user.userId,
                                        userName: user.emailId,
                                        currentRoleName: user.roleName,
                                        roleName: user.roleName,
                                        roleId: user.roleId,
                                    }));
                                }}
                            >
                                <img src="images/edit-icon.svg" alt="Edit" title="Edit" />
                            </button>
                        )}
                    </td>
                </tr>
            ));
        } catch (error) {
            console.log(error);
        }
    }

    const handleSortClick = (column: string) => {
        setManageUserFilter((prevState) => {
            if (prevState.sortColumn === column) {
                // If the same column is clicked again, toggle the sort order
                return {
                    ...prevState,
                    sortColumn: column,
                    sortType: prevState.sortType === 'asc' ? 'desc' : 'asc',
                    startIndex: 0
                };
            } else {
                // If a different column is clicked, reset the sort order to 'asc'
                return {
                    ...prevState,
                    sortColumn: column,
                    sortType: 'asc',
                    startIndex: 0
                };
            }
        });
    };

    const renderSortArrow = (column: string) => {
        if (manageUserFilter.sortColumn === column) {
            return manageUserFilter.sortType === 'asc' ? (
                <img src="images/sort-arrow-up.svg" alt="sort-arrow-up" className="cust-sort-arrow-color" />
            ) : (
                <img src="images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />
            );
        }
        return <img src="images/sort-down-arrow-white.svg" alt="sort-arrow-down" className="cust-sort-arrow-color" />;
    };
    //PS_2.7-2.19
    const validation = () => {
        let count = 0;
        let errors = insertErrorObj;
        // Validation for username
        if (!insertUser.name) {
            errors.name = "Please enter username";
            count++
        }

        // Validation for email
        if (!insertUser.emailId || !isValidEmail(insertUser.emailId)) {
            errors.emailId = "Please enter valid email address";
            count++
        }

        // Validation for roleId
        if (insertUser.roleId == 0) {
            errors.role = "Please select role";
            count++
        }
        setInsertError(errors);
        if (count == 0) {
            insertUserDetails()
        }
    }
    //PS_3.6 -3.10
    const updateValidation = () => {
        let count = 0;
        let updateValidate = ""
        // Validation for roleId
        if (updateUser.roleId == 0) {
            updateValidate = "Please enter role";
            count++
        }
        setUpdateError(updateValidate);
        if (count == 0) {
            updateUserDetails()
        }
    }
    //PS_3.10 - 3.22
    async function updateUserDetails() {
        try {
            setLoader(true)
            let result = await updateUserDetail(updateUser)
            if (result?.data?.statusCode == 200) {
                getUsers();
                setToastMessage(`User Updated Successfully`)
            } else {
                setErrorPopup(true);
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoader(false)
            setUpdateUser(UpdateObj);
            setUpdate([])
        }
    }
    console.log(manageUserFilter);
    return (

        <>
            {loader ? <><Loader /></> : <></>}
            {ToastMessage ?
                <div>
                    {SuccessToast(ToastMessage)}
                </div> : <></>
            }
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="px-4 py-4 mb-3 border-0 rounded-3">
                            <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">

                                <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                                    <li className="nav-item cust-nav-item" role="presentation">
                                        <button
                                            className="nav-link cus-tab-link cus-tab-border color-grey border-0 ps-0 font-semibold font-14 nav-bg active"
                                            id="mangeusers-tab" data-bs-toggle="tab" data-bs-target="#mangeusers" type="button"
                                            role="tab" aria-controls="mangeusers" aria-selected="true">Manage Users</button>
                                    </li>
                                    <li className="nav-item cust-nav-item" role="presentation">
                                        <button
                                            className="nav-link cus-tab-link cus-tab-border border-0 color-grey font-semibold font-14 nav-bg"
                                            id="audithistory-tab" data-bs-toggle="tab" data-bs-target="#audithistory"
                                            type="button" role="tab" aria-controls="audithistory" aria-selected="false">Audit
                                            History</button>
                                    </li>

                                </ul>

                                <div className="d-flex gap-3">
                                    <div className="input-group input-search">
                                        <input value={manageUserFilter.search} onChange={(e) => {
                                            //PS_6.0-6.2
                                            setManageUserFilter(prevState => ({
                                                ...prevState,
                                                search: e.target.value.trim()
                                            }));
                                        }} type="search" className="form-control border-0 font-14 font-regular primary-bg-color"
                                            onKeyDown={e => {
                                                if (e.key === "Enter") if (manageUserFilter.startIndex == 0) {
                                                    getUsers()
                                                } else {
                                                    setManageUserFilter(prevState => ({
                                                        ...prevState,
                                                        startIndex: 0
                                                    }));
                                                }
                                            }}
                                            placeholder="Search" ></input>
                                        <button className="btn border-0 search-input-btn primary-bg-color" type="button"><img
                                            src="images/search-icon.svg" alt="search" /></button>
                                    </div>

                                    <button className="btn filter-btn border-0 custom-border-box-shadow primary-bg-color" data-bs-toggle="dropdown" aria-expanded="true">
                                        <img src="images/filter-icon.svg" alt="filter-icon" />
                                    </button>

                                    <div className="dropdown-menu dropdown-menu-end border-0 shadow dd-position p-3 custom-filter-width primary-bg-color"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <h5 className="font-bold font-20 mb-0 custom-header-color">Advanced Filter</h5>
                                            <span><img className="cursor-pointer close-img" src="images/cross-icon-black.svg"
                                                alt="close" /></span>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                <label className="form-label font-14 font-semibold label-color " htmlFor="Status">Is
                                                    Active</label>
                                                <select onChange={(e) => {
                                                    //PS-4.0 -4.2
                                                    setManageUserFilter(prevState => ({
                                                        ...prevState,
                                                        isActive: e.target.value
                                                    }));
                                                }}
                                                    className="form-select custom-form-input primary-bg-color custom-select-arrow primary-border-color"
                                                    aria-label="Default select example" value={manageUserFilter.isActive}>
                                                    <option value="">Select</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-end align-items-center py-3 ">
                                                    <button  className="btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => {
                                                        setRefresh(Math.random()); // Triggers a refresh or re-render
                                                        setManageUserFilter(prevState => ({
                                                            ...prevState, // Spread the old state
                                                            search: "", // Reset the search to an empty string
                                                            isActive: "", // Reset isActive filter
                                                            startIndex: 0, // Reset startIndex to 0
                                                            sortColumn: "", // Clear the sort column
                                                            sortType: "desc" // Set the sort type to "desc"
                                                        }));
                                                    }}> Cancel</button>
                                                    <button
                                                        className={manageUserFilter.isActive == null || manageUserFilter.isActive == "" ? "btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4 disabled": "btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"}
                                                        onClick=
                                                        {//PS_8.2
                                                            () => {
                                                                if (manageUserFilter.startIndex == 0) {
                                                                    getUsers()
                                                                } else {
                                                                    setManageUserFilter(prevState => ({
                                                                        ...prevState,
                                                                        startIndex: 0
                                                                    }));
                                                                }
                                                            }}>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="mangeusers" role="tabpanel"
                                    aria-labelledby="mangeusers-tab">
                                    <div className="table-responsive theme-table bg-white ">
                                        <table className="table table-borderless mb-0">
                                            <thead>
                                                <tr>
                                                    {// //PS_7.2
                                                    }
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('username')}>
                                                        Username<span className="ms-1">{renderSortArrow('username')}</span>
                                                    </th>
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('email_id')}>
                                                        Email Address<span className="ms-1">{renderSortArrow('email_id')}</span>
                                                    </th>
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('role')}>
                                                        Role<span className="ms-1">{renderSortArrow('role')}</span>
                                                    </th>
                                                    <th className="text-nowrap">Is Active</th>
                                                    <th className="text-nowrap cursor-pointer" onClick={() => handleSortClick('status_value')}>
                                                        Status<span className="ms-1">{renderSortArrow('status_value')}</span>
                                                    </th>
                                                    <th className="text-nowrap text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-nowrap">
                                                        <input
                                                            type="text" value={insertUser.name} maxLength={64}
                                                            className="form-control custom-form-input primary-bg-color primary-border-color form-drop"
                                                            placeholder="Enter Username"
                                                            onChange={(e) => {
                                                                setInsertUser((prevState) => ({
                                                                    ...prevState,
                                                                    name: e.target.value.trim(),
                                                                }));
                                                            }}
                                                        />
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">{insertError.name}</label>
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <input value={insertUser.emailId} type="text" maxLength={64}
                                                            className="form-control custom-form-input primary-bg-color primary-border-color form-drop"
                                                            placeholder="Enter Email Address" onChange={(e) => {
                                                                setInsertUser((prevState) => ({
                                                                    ...prevState,
                                                                    emailId: e.target.value.trim(),
                                                                }));
                                                            }}></input>
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">{insertError.emailId}</label>
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap">
                                                        <select value={insertUser?.roleId} onChange={(e) => {
                                                            // Update insertUser state
                                                            setInsertUser(prevState => ({
                                                                ...prevState,
                                                                roleId: Number(e.target.value),
                                                                roleName: e.target.options[e.target.selectedIndex].className
                                                            }))
                                                        }}
                                                            className="form-select custom-form-input custom-select-arrow primary-border-color form-drop"
                                                            aria-label="Default select example">
                                                            <option value={0} selected>Select</option>
                                                            {buildDropDown()}
                                                        </select>
                                                        <div className="pt-1">
                                                            <label className="required-color font-12 font-regular">{insertError.role}</label>
                                                        </div>
                                                    </td>
                                                    <td className="text-nowrap"></td>
                                                    <td className="text-nowrap"></td>
                                                    <td className="text-center">
                                                        <button type="button" className="border-0 m-0 px-3 bg-transparent" onClick={() => { validation() }}>
                                                            <img src="images/red-plus-icon.svg" alt="Add" title="Add"></img>
                                                        </button>
                                                    </td>
                                                </tr>
                                                {buildUsers()}
                                                {noDataFound ? <NoDataFound /> : <></>}
                                                {noResultsFound ? <NoResultsFound /> : <></>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-3 d-flex justify-content-between">
                                        <p className="mb-0 font-14 font-regular color-grey-v1 "># of Records :
                                            <span className="color-black-v1 font-semibold primary-header-v1 font-18">{` ${userDetails.length} `} </span>out of
                                            <span className="color-black-v1 font-semibold primary-header-v1 font-18">{` ${totalCount}`}</span>
                                        </p>
                                        <div >
                                            {totalCount > 10 ? <>
                                                <ReactPaginate
                                                    previousLabel={
                                                        <span className="Page navigation example">
                                                            <img src="images/previous-icon.svg" alt="previous-icon" className="previous-icon" />
                                                        </span>
                                                    }
                                                    nextLabel={
                                                        <span className="Page navigation example">
                                                            <img src="images/next-icon.svg" alt="next-icon" className="next-icon" />
                                                        </span>
                                                    }
                                                    previousClassName={'pagination mb-0 d-flex align-items-center'}
                                                    nextClassName={'pagination mb-0 d-flex align-items-center'}
                                                    previousLinkClassName={"border-0 custom-page-link d-flex align-items-center me-2"}
                                                    nextLinkClassName={'border-0 custom-page-link d-flex align-items-center ms-2'}
                                                    disabledClassName={'disabled'}
                                                    breakLabel={'...'}
                                                    breakClassName={"page-link border-0 custom-page-link bg-transparent align-items-center d-flex font-14 secondary-text-color"}
                                                    breakLinkClassName={'page-link'}
                                                    // Total number of pages
                                                    pageCount={Math.ceil(totalCount / 10)}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={(selectedPage) => {
                                                        // Update the startIndex in the filter object based on the selected page
                                                        const newFilter = { ...manageUserFilter, startIndex: (selectedPage.selected) * 10 };
                                                        // Update the state variable
                                                        setManageUserFilter(newFilter);
                                                    }}
                                                    containerClassName={'pagination mb-0 d-flex align-items-center'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link border-0 custom-page-link bg-transparent align-items-center d-flex font-14 secondary-text-color'}
                                                    activeClassName={'active h-auto'}
                                                    hrefBuilder={() => '#'}
                                                /></> : <></>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageUsers