import { ReactNode, useState} from 'react'
import Loader from "./ResuableComponents/Loader"
import React from 'react';

interface AuthPageLayoutProps {
  children?: ReactNode;
}

export const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({ children }) => {
  const [loader, setLoader] = useState(false);

  const showLoader = () => {
    setLoader(true);
  };
  

  const hideLoader = () => {
    setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <div className="container-fluid">
        <div className="row">
          {/* Left container */}
          <div className="col-xl-6 d-xl-block d-none p-3">
            <div className="login-bg-pos"> 
              <div className="text-center pt-5">
                <span className="logo-style logo mt-5"></span>
                <p className="login-header font-medium mt-3">Unlock the gateway to <span className="font-bold gredient-text">efficient</span> logistics management</p>
              </div>
            </div>
          </div>
          {/* Right container */}
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
            {/* Pass the functions to children */}
            {React.Children.map(children, (child) =>
              React.cloneElement(child as React.ReactElement<any>, { showLoader, hideLoader })
            )}
          </div>
        </div>
      </div>
    </>
  );
};
