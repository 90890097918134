function Profile() {

    
    return (
        <>
            <div>
                <p></p>
            </div>
        </>
    )
}

export default Profile;