//PS_3PL_1.0
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { encryptStorage } from "../Constant";
import { StatusInfo, ThreePlGrid, ThreePlRequest } from "../interface/Interface";
import { get3plOrganizationDetails, get3plStatus } from "../service/Api"; // Assuming these functions are exported from api.ts
import Loader from "./ResuableComponents/Loader";
import NoDataFound from "./ResuableComponents/NoDataFound";
import NoResultsFound from "./ResuableComponents/NoResultsFound";
import PaginationComponent from "./ResuableComponents/PaginationComponent";
import { SomethingWentWrong } from "./ResuableComponents/SomethingWentWrong";
import { SuccessToast } from "./ResuableComponents/SuccessToast";

//PS_3PL_1.0
let default3plRequest: ThreePlRequest = {
    startIndex: 0,
    endIndex: 10,
    sortColumn: '',
    sortType: '',
    searchData: '',
    fromDate: '',
    toDate: '',
    statusId: "",
    connectionStatusId: ""
};

//PS_3PL_1.1
const ThreePLGrid = () => {
    const [sortHide, setSortHide] = useState<string>('');
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [openToastMessage, setOpenToastMessage] = useState<boolean>(location.state?.openToastMessage || false);
    const actions = location?.state?.action
    //PS_3PL_1.2
    const [organizationDetails, setOrganizationDetails] = useState<ThreePlGrid[]>([]);
    //PS_3PL_1.4
    const [statusDetails, setStatusDetails] = useState<any>({});
    //PS_3PL_1.5
    const [noResults, setNoResults] = useState(false);
    //PS_3PL_1.3
    const [threeplRequest, set3plRequest] = useState(default3plRequest);
    //PS_3PL_1.5
    const [totalPages, setTotalPages] = useState(0);
    //PS_3PL_1.4
    const [openSomethingWentWrongPopup, setOpenSomethingWentWrongPopup] = useState(false);

    //PS_3PL_1.4
    const [notFound, setNotFound] = useState(false);
    const [totalCount, setTotalCount] = useState(0)
    const navigate = useNavigate();
    //PS_3PL_1.7
    //use Effect to retireve data 
    useEffect(() => {
        retrieve3plStatus();
        retrieve3plGrid(threeplRequest);
    }, []);
    //PS_3PL_1.50
    //Change total Count
    useEffect(() => {
        //PS_3PL_1.51
        let totalPagesCount = Math.ceil(totalCount / 10); setTotalPages(totalPagesCount);
    }, [totalCount]);
    // display taoster message

    useEffect(() => {
        if (openToastMessage) {
            const timer = setTimeout(() => {
                setOpenToastMessage(false); // Hide the toast message after 10 seconds
            }, 3000);

            return () => clearTimeout(timer); // Clear the timer on component unmount or when openToastMessage changes
        }
    }, [openToastMessage]);
    //PS_3PL_1.7
    /**
     * This function retrieves the status data and checks if data is present 
     */

    const retrieve3plStatus = async () => {

        try {
            setLoader(true)
            const response = await get3plStatus();
            //PS_3PL_1.8
            if (response.data.statusCode == 200) {

                //PS_3PL_1.9
                setStatusDetails(response.data.data);
            } else {
                //PS_3PL_1.9
                setStatusDetails({});
            }
        } catch (e: any) {
            console.error(e);

        } finally {
            setLoader(false)
        }

    };
    //PS_3PL_1.49
    /**
     * This Function handles removal of filters and retrieves grid data
     */
    const handleClear = () => {

        const updated3plRequest = {
            ...threeplRequest,
            statusId: "",
            connectionStatusId: "",
            searchData: "",
            fromDate: "",
            toDate: ""
        }
        set3plRequest(updated3plRequest);
        retrieve3plGrid(updated3plRequest);

    }
    //PS_3PL_1.14
    /**
     * This Function gets the Organization details from Backend endpoint
     * @param request 
     */
    const retrieve3plGrid = async (request: ThreePlRequest) => {
        try {
            setLoader(true)
            //PS_3PL_1.15
            const response = await get3plOrganizationDetails(request);
            //PS_3PL_1.17
            if (response.data.statusCode === 200) {
                setOrganizationDetails(response.data.data);
                if (response.data.data.length === 0) {
                    if (request.searchData || request.fromDate || request.toDate || request.statusId || request.connectionStatusId || request.fromDate || request.toDate) {
                        setTotalCount(0)
                        setNoResults(true)
                    } else {
                        setTotalCount(0)
                        setNotFound(true)
                    }
                } else {
                    setTotalCount(response.data.totalCount ? response.data.totalCount : 0)
                    setNotFound(false)
                    setNoResults(false)
                }
                //PS_3PL_1.18
            } else if (response.status !== 200) {
                setOrganizationDetails([]);
                setNotFound(true);
                setOpenSomethingWentWrongPopup(true);
            }

        } catch (e: any) {
            setOpenSomethingWentWrongPopup(true);
            if (request.searchData || request.fromDate || request.toDate || request.statusId || request.connectionStatusId || request.fromDate || request.toDate) {
                setNoResults(true)
            } else {
                setNotFound(true)
            }
            console.error(e);
        } finally {
            setLoader(false)
        }
    };
    //PS_3PL_1.13
    const bindCompanyStatusDropDown = () => {
        return statusDetails?.companyStatus?.map((obj: StatusInfo) => {
            return (<option value={obj.id}>
                {obj.status}
            </option>)

        });

    }
    //PS_3PL_1.13
    /**
     * This function gets the all the status drop values from a state and 
     * then binds the Connection status values using 
     * 
     * @returns Jsx.ELement with DropDown data
     */
    const bindConnectionStatusDropDown = () => {
        return statusDetails?.connectionStatus?.map((obj: StatusInfo) => {
            return (<option value={obj?.id}>
                {obj?.status}
            </option>)
        });

    }
    //PS_3PL_1.22
    /**
     * This function checks if the values are present in the details state variable and 
     * If there are no values present the show no records found if filters are present then show no results founds
     * If values are present then all the datas are set onto <td> tags and return
     * @returns Jsx.ELement with Grid table data
     */
    const bind3plGrid = () => {
        //PS_3PL_1.22
        return organizationDetails?.map((obj: ThreePlGrid) => {
            //PS_3PL_1.34
            return (
                <tr>
                    <td className="text-nowrap">{obj?.companyName}</td>
                    <td className="text-nowrap">{obj?.userName}</td>
                    <td className="text-nowrap">{obj?.businessEmail}</td>
                    <td className="text-nowrap">{obj?.phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')}</td>
                    <td className="text-nowrap">{obj?.status}</td>
                    {obj?.connectionStatus.toLowerCase() === "online" ? <> <td className="text-nowrap">
                        <span className="d-flex align-items-center">
                            <span className="green-legend me-2" />
                            <span>Online</span>
                        </span>
                    </td></> : <> <td className="text-nowrap">
                        <span className="d-flex align-items-center">
                            <span className="red-legend me-2"></span>
                            <span>Offline</span></span>
                    </td></>}

                    <td className="text-nowrap">{obj?.validTill === "" ? '-' : format(new Date(obj?.validTill), 'MM/dd/yyyy')}</td>                    <td className="text-center d-flex justify-content-center">
                        <button
                            type="button"
                            className="border-0 m-0 border-end px-3 bg-transparent"
                            onClick={() => { navigate("/3plOnboarding", { state: { companyId: obj?.companyId, companyName: obj?.companyName } }) }}
                        >
                            <img src="images/edit-icon.svg" alt="Edit" title="Edit" />
                        </button>
                        <button
                            disabled={obj.status === "Inactive" || obj.connectionStatus.toLowerCase() === "offline" ? true : false}
                            type="button"
                            className="border-0 m-0 bg-transparent px-3"
                            onClick={() =>
                            //PS_3PL_1.25
                            {
                                navigate("/Landing")
                                encryptStorage.setItem(
                                    "companyId",
                                    obj?.companyId)
                            }}
                        >
                            <img
                                src="images/impersonation.svg"
                                alt="Impersonate"
                                title="Impersonate"
                            />
                        </button>
                    </td>
                </tr>)
        });

    }
    //PS_3PL_1.27
    /**
     * This function is used to set the request state 
     * then grid data is again receieved using updated request
     * @param event 
     */
    const handleSort = async (event: any) => {

        try {
            // setLoader(true)
            //PS_3PL_1.28
            const sortId = event.target.id;
            const sortValues = sortId.split("@");
            //PS_3PL_1.29
            const updated3plRequest = {
                ...threeplRequest,
                sortColumn: sortValues[0],
                sortType: sortValues[1],
            };
            set3plRequest(updated3plRequest);
            retrieve3plGrid(updated3plRequest);
            setSortHide(sortId)
        } catch (e: any) {
            console.log(e)
        } finally {
            setLoader(false)
        }

    };

    const handleSearch = async () => {
        await retrieve3plGrid(threeplRequest);
    };
    //PS_3PL_1.57
    /**
     * This function is used to use to update the page change click
     * the selected page is sent as start index * 10 
     * @param data 
     */
    const handlePageClick = (data: { selected: number; }) => {

        //PS_3PL_1.57
        const pageUp = {
            ...threeplRequest,
            startIndex: data.selected * 10,
        }
        set3plRequest(pageUp);
        //PS_3PL_1.58
        retrieve3plGrid(pageUp)
    }
    return (
        <>
            <div>
                {loader ? <Loader /> : <></>}
                {openSomethingWentWrongPopup ? <SomethingWentWrong onClose={() => setOpenSomethingWentWrongPopup(false)} /> : null}

                {openToastMessage && (actions == "create" ? SuccessToast("Created 3PL Successfully") : SuccessToast(" 3PL details updated successfully"))}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="px-4 py-4 mb-3 border-0 rounded-3">
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                    <h5 className="mb-0 color-black-v1 font-22 font-bold">3PL</h5>
                                    <div className="d-flex gap-3 w-md-100">
                                        <div className="input-group input-search">
                                            <input
                                                type="search"
                                                className="form-control border-0 font-14 font-regular primary-bg-color"
                                                placeholder="Search"
                                                onKeyDown={e => { if (e.key === "Enter") retrieve3plGrid(threeplRequest) }}
                                                onChange={e => set3plRequest({ ...threeplRequest, searchData: e.target.value })}
                                                value={threeplRequest.searchData}
                                            />
                                            <button
                                                className="btn border-0 search-input-btn primary-bg-color"
                                                type="button"
                                                onClick={handleSearch}
                                            >
                                                <img src="images/search-icon.svg" alt="search" />
                                            </button>
                                        </div>
                                        {/* <button class="btn filter-btn border-0 custom-border-box-shadow">
                          <img src="images/filter-icon.svg" alt="filter-icon">
                      </button> */}
                                    <button
                                        className="btn filter-btn border-0 custom-border-box-shadow primary-bg-color"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    >
                                        <img src="images/filter-icon.svg" alt="filter-icon" />
                                    </button>
                                    {/*filter*/}
                                    <div
                                        className="dropdown-menu dropdown-menu-end border-0 shadow dd-position p-3 custom-filter-width secondary-bg-color"
                                        aria-labelledby="dropdownMenuLink"
                                    >
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <h5 className="font-bold font-20 mb-0 custom-header-color form-text-color">
                                                Advanced Filter
                                            </h5>
                                            <span>
                                                <img
                                                    className="cursor-pointer left-arrow"
                                                    src="images/cross-icon-black.svg"
                                                    alt="close"
                                                />
                                            </span>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    className="form-label font-14 font-semibold label-color form-text-color"
                                                    htmlFor="Status"
                                                >
                                                    Status
                                                </label>
                                                <select
                                                    className="form-select custom-form-input secondary-bg-color form-field-border"
                                                    aria-label="Default select example"
                                                    id="Status"
                                                    style={{ cursor: 'pointer' }}
                                                    onChange={e => set3plRequest({ ...threeplRequest, statusId: e.target.value })}
                                                    value={threeplRequest.statusId}
                                                >
                                                    <option value={"0"}>Select</option>
                                                    {bindCompanyStatusDropDown()}
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    className="form-label font-14 font-semibold label-color form-text-color"
                                                    htmlFor="connectionstatus"
                                                >Connection Status
                                                </label>
                                                <select
                                                    className="form-select custom-form-input secondary-bg-color form-field-border"
                                                    aria-label="Default select example"
                                                    id="connectionstatus"
                                                    style={{ cursor: 'pointer' }}
                                                    //PS_3PL_1.50
                                                    onChange={e => set3plRequest({ ...threeplRequest, connectionStatusId: e.target.value })}
                                                    value={threeplRequest.connectionStatusId}
                                                >
                                                    <option value={"0"}>Select</option>
                                                    {bindConnectionStatusDropDown()}
                                                </select>
                                            </div>
                                            <div className="col-md-12 mb-2">
                                                <label className="font-16 font-semibold custom-header-color form-text-color">
                                                    Valid Till
                                                </label>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    className="form-label font-14 font-semibold label-color form-text-color"
                                                    htmlFor="from"
                                                >
                                                    From
                                                </label>
                                                <div className="position-relative">
                                                    <input className="form-control custom-form helper-text secondary-bg-color form-field-border" type="date" id="from"
                                                        placeholder="MM/DD/YYYY"
                                                        max="9999-12-31"
                                                        value={threeplRequest.fromDate}
                                                        onChange={e => set3plRequest({ ...threeplRequest, fromDate: e.target.value })}
                                                    />
                                                    {/* <img src="images/calander-icon.svg" alt="calander-icon" className="position-absolute custom-input-eye-color custom-input-icon" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    className="form-label font-14 font-semibold label-color form-text-color"
                                                    htmlFor="to"
                                                >
                                                    To
                                                </label>
                                                <div className="position-relative">
                                                    <input className="form-control custom-form helper-text secondary-bg-color form-field-border" type="date" id="to"
                                                        placeholder="MM/DD/YYYY"
                                                        max="9999-12-31"
                                                        value={threeplRequest.toDate}
                                                        onChange={e => set3plRequest({ ...threeplRequest, toDate: e.target.value })}
                                                    />
                                                    {/* <img src="images/calander-icon.svg" alt="calander-icon" className="position-absolute custom-input-eye-color custom-input-icon" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-end align-items-center py-3">
                                                    <button
                                                        className="btn text-nowrap w-md-100 font-medium color-black-v1 font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4 form-text-color"
                                                        onClick={() => { handleClear() }}>
                                                        Cancel
                                                    </button>
                                                    <button

                                                            disabled={(!threeplRequest.connectionStatusId || threeplRequest.connectionStatusId === '0') && (!threeplRequest.statusId || threeplRequest.statusId === '0') && !threeplRequest.fromDate && !threeplRequest.toDate}
                                                            className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                                                            onClick={() => retrieve3plGrid(threeplRequest)}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*filter*/}
                                        <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                                            onClick={() => { navigate("/3plOnboarding") }}>
                                            <img src="images/add-icon-white.svg" alt="add-plus-icon"
                                            />
                                            Logistic Partner
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive theme-table bg-white">
                                    <table className="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap"
                                                    id="companyName"
                                                >
                                                    Company Name
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-arrow-up.svg" id="companyName@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "companyName@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-black.svg" id="companyName@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "companyName@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap"
                                                    id="username"
                                                >
                                                    Username
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-arrow-up.svg" id="userName@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "userName@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-black.svg" id="userName@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "userName@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap"
                                                    id="businessEmailAddress">
                                                    Business Email Address
                                                    <span className="ms-1 cursor-pointer">
                                                    </span>
                                                </th>
                                                <th className="text-nowrap"
                                                >Phone #</th>
                                                <th className="text-nowrap">
                                                    Status
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-arrow-up.svg" id="status@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "status@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-black.svg" id="status@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "status@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap">
                                                    Connection Status
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-arrow-up.svg" id="connectionstatus@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "connectionstatus@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-black.svg" id="connectionstatus@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "connectionstatus@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap" >
                                                    Valid Till
                                                    <span className="ms-1 cursor-pointer">
                                                        <><img src="images/sort-arrow-up.svg" id="validityTill@desc" alt="sort-arrow-up" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "validityTill@asc" || "" ? false : true
                                                        } onClick={e => { handleSort(e) }} /></>
                                                        <><img src="images/sort-down-arrow-black.svg" id="validityTill@asc" alt="sort-arrow-down" className="cust-sort-arrow-color" hidden={
                                                            sortHide === "validityTill@asc" || "" ? true : false
                                                        } onClick={e => { handleSort(e) }} /></>
                                                    </span>
                                                </th>
                                                <th className="text-nowrap text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* PS_3PL_1.19*/}
                                            {organizationDetails.length > 0 ? bind3plGrid() : (noResults ? <NoResultsFound /> : (notFound ? <NoDataFound /> : null))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-3 d-flex justify-content-between">
                                    <p className="mb-0 font-14 font-regular color-grey-v1 ">
                                        # of Records :
                                        <span className="color-black-v1 font-semibold font-18 primary-header-v1">
                                            {` ${organizationDetails.length} `}
                                        </span>
                                        out of
                                        <span className="color-black-v1 font-semibold font-18 primary-header-v1">
                                            {` ${totalCount} `}
                                        </span>
                                    </p>
                                    {totalPages > 1 ? <>
                                        <PaginationComponent totalCount={totalCount} onPageChange={handlePageClick} /> </> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ThreePLGrid;