import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'; // Assuming you'll use this later
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ChildComponentProps } from "../interface/Interface";

export const ForgotPasswordComponent: React.FC<ChildComponentProps> = ({ showLoader, hideLoader }) => {

    //PC_FP_08-Hook used to navigate
    const location = useLocation();
    const navigate = useNavigate();

    //PC_FP_06-PC_FP_07-State variables to store email id and error
    const [emailId, setEmailId] = useState("");
    const [error, setError] = useState("");

    let navigateTo = ""
    if (location?.state?.pathname === "/admin") {
        navigateTo = location.state.pathname;
    } else {
        navigateTo = "/";
    }

    //PC_FP_09-Store the email id from sign page
    useEffect(() => {
        setEmailId(location?.state?.emailId);
    }, []);

    //PC_FP_11-PC_FP_13-on change functionality for email id field
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailId(e.target.value.trim());
    }

    //PC_FP_14-PC_FP_21-Submit functionality for send verification code
    const handleSubmit = () => {
        let isValid = validateEmail()
        if (isValid) {
            showLoader();
            const poolData = {
                UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
                ClientId: process.env.REACT_APP_CLIENT_ID || "",
            };

            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: emailId,
                Pool: userPool,
            });
            cognitoUser.forgotPassword({

                onSuccess: function (result) {
                    hideLoader()
                    //PC_FP_24-Navigate to verification page
                    navigate("/verification", { state: { emailId: emailId } });
                },
                onFailure: function (err) {
                    handleAuthFailure(err);
                    hideLoader()
                }
            });
        }
    }


       //PC_SI_55-PC_SI_58 - Method to check error and display the error message to the user
       const handleAuthFailure = (err: any) => {
        if (err.code === "UserNotFoundException" || err.message.includes("Incorrect username or password.")) {
            setError("Incorrect Email Address or Password" );
        } else if (err.code === "NotAuthorizedException") {
            setError("Sorry, you are unauthorized to access this application" );
        } else {
            setError(err.message );
        }
    }

    //PC_FP_15-PC_FP_19-Validation of email
    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailId.trim() || !emailRegex.test(emailId)) {
            setError("Please enter a valid email address");
            return false
        } else {
            setError("");
            return true;
        }
    }


    return (
        <div className="row justify-content-center pt-5">
            <div className="row justify-content-center pt-5">
                <div className="col-md-10 col-sm-12 pt-4">
                    <h5 className="font-26 font-bold primary-text-color mb-2">Forgot Password</h5>
                    <p className="font-16 font-regular secondary-text-color mb-4">Please enter your registered business email address to reset
                        password. </p>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="EmailAddress" className="form-label custom-label mb-1">Business Email Address</label>
                                <input type="text" value={emailId} className="form-control custom-form-input" id="EmailAddress" placeholder="Enter Business Email Address" onChange={handleOnChange}  onKeyDown={ e => { if (e.key === "Enter") handleSubmit() } }/>
                                <div className="pt-1">
                                    <label className="required-color font-12 font-regular">{error}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn w-100 primary-btn mt-4 mb-2" onClick={handleSubmit}>Send Verification Code</button>
                    {location?.state?.pathname === "/admin" && (
                        <p className="text-center font-14 font-regular primary-text-color mt-3 mb-lg-0 mb-4">
                            Need an account?<a href="" className="text-decoration-none link-color font-14 font-medium" onClick={(e) => navigate('/signup')}> Sign Up</a>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
