import axios from 'axios';
//PC_SI_32-/PC_SI_26-Method to connect API using axios
export async function client(
    endpoint: String,
    { requestType, body, ...customConfig }: any = {},
  ) {
    const headers: any = {
      'Content-Type': process.env.REACT_APP_HEADER_CONTENT_TYPE,
      'Authorization':`Bearer ${sessionStorage.getItem('accessToken')}`
    };
  
    let expiredTime: any;
    if (localStorage.getItem('accessTokenExpiration') != null) {
      expiredTime = sessionStorage.getItem('accessTokenExpiration')
    }
    var CurrentTime = new Date();
    var currentexpireTime = new Date(expiredTime);
    if (CurrentTime < currentexpireTime) {
      headers.Authorization = `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    else {  
        
    }
  
    const requestConfig: any = {
      method: requestType,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers
      }
    };
    if (body) {
      requestConfig.data = JSON.stringify(body);
    }
    const url = process.env.REACT_APP_BASE_URL + `${endpoint}`;
    
    const apiResponse = await axios(url, requestConfig).catch((error: any) => {
      console.log(error);
      if (error?.response?.status != 200) {
        console.log(error);
        return error?.response
      }
    });
    return apiResponse;
  }

  export const ConnectionTestclient = async (request: any) => {
    
    const apiResponse = await axios(request);
    return apiResponse;
  };