export function SuccessToast(message: String) {
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="toast toast-bg position-absolute alert p-0 rounded-3 border-0 alert-dismissible fade show mx-auto" role="alert"
                    aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body d-flex py-2 align-items-center">
                            <img src="images/success-toast-icon.svg" alt="success-toast-icon" />
                            <label className="font-14 primary-text-color font-medium ms-2">{message}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}